import { Button } from 'react-bootstrap'
import React from 'react'
import { Link, Navigate } from "react-router-dom"
import Navbar from '../../components/Navbar'
import ShowCPU from '../../components/ShowCPU'
import Footer from '../../components/Footer'

function Home() {

  const isAdmin = window.sessionStorage.getItem('role')
  const showButton = (isAdmin == "admin")

  return (
    <div className='container'>
      <Navbar />
      <h1>Données de la CPU </h1>
      <ShowCPU />
      <Footer/>
    </div>
  )
}

export default Home