import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import findOneInterventionRequest from '../../api/apiIntervention/findOneInterventionRequest'
import updateInterventionRequest from '../../api/apiIntervention/updateInterventionRequest'
import readFilteredEquipmentRequest from '../../api/apiEquipment/readFilteredEquipmentRequest'
import Navbar from '../../components/Navbar'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/Footer'
import DateInput from '../../components/DateInput'

const EditInterventionPage = () => {
  const { id } = useParams()
  const [data, setData] = useState([])
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [equipmentName, setEquipmentName] = useState('')
  const [doneBy, setDoneBy] = useState('')
  const [description, setDescription] = useState('')
  const [stoppage, setStoppage] = useState('')
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem('companyName'))

  const [equipmentList, setEquipmentList] = useState([])


  useEffect(() => {
    findOneInterventionRequest(id)
      .then(res => {
        setDate(res.date)
        setTime(res.time)
        setEquipmentName(res.equipmentName)
        setDoneBy(res.doneBy)
        setDescription(res.description)
        setStoppage(res.stoppage)
        setCompanyName(res.companyName)
      })
    }, [])
    useEffect(()=>{
      readFilteredEquipmentRequest(companyName)
      .then(res => setEquipmentList(res))
    },[])

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      id, date, time, equipmentName, doneBy, description, stoppage, companyName
    }
    updateInterventionRequest(data)
      .then(res =>
        navigate('/interventions')
      )
  }
  return (
    <div className='container'>
      <Navbar />
      <h1>Edit Intervention Page</h1>
      <div >
        <Form className='d-flex flex-column m-2 gap-2' onSubmit={handleSubmit}>

          <DateInput setDate={setDate} date ={date} />

          <InputGroup>
            <InputGroup.Text>Time</InputGroup.Text>
            <Form.Control placeholder='time' type="time" name='time' onChange={(e) => setTime(e.target.value)} value={time ? time : ""} />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Done By</InputGroup.Text>
            <Form.Control placeholder='Done By' readOnly name='doneBy' onChange={(e) => setDoneBy(e.target.value)} value={doneBy ? doneBy : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Equipment</InputGroup.Text>
            <Form.Select
                aria-label="Default select example"
                onChange={(e) => setEquipmentName(e.target.value)}
                value={equipmentName}>
                <option>Select an equipment</option>
                {equipmentList.map((e, i) => {
                  return (
                    <option key={i} value={e.reference}>
                      {e.reference}
                    </option>
                  )
                })}
              </Form.Select>
          </InputGroup>

          {/* <input placeholder='equipment name' name='equipmentName' onChange={(e) => setEquipmentName(e.target.value)} value={equipmentName} ></input> */}

          <InputGroup>
            <InputGroup.Text>Description</InputGroup.Text>
            <Form.Control placeholder='Description of the task' onChange={(e) => setDescription(e.target.value)} name='description' value={description ? description : ""} />
          </InputGroup>

          {/* <input placeholder='Description of the task' onChange={(e) => setDescription(e.target.value)} name='description' value={description}></input> */}
          
          
          <InputGroup>
            <InputGroup.Text>Stoppage</InputGroup.Text>
            <Form.Control placeholder='Stoppage time' type="time" name="stoppage" onChange={(e) => setStoppage(e.target.value)} value={stoppage ? stoppage : ""} />
          </InputGroup>

          {/* <input placeholder='Stoppage time' name="stoppage" onChange={(e) => setStoppage(e.target.value)} value={stoppage}></input> */}
          {/* <input placeholder='Comapny Name' name='companyName' onChange={(e) => setCompanyName(e.target.value)} value={companyName}></input> */}
          <Button type='submit'>Update</Button>
        </Form>
      </div>
      <Footer/>
    </div>
  )
}

export default EditInterventionPage