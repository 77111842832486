import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import readCompanyRequest from '../api/apiCompany/readCompanyRequest';
import removeCompanyRequest from '../api/apiCompany/removeCompanyRequest';

function UserForm() {
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [_id, setId] = useState("")

    const handleEdit = (e) => {
        navigate(`edit/${e.target.value}`)
    }

    useEffect(() => {
        readCompanyRequest()
            .then(res => { setData(res) });
    }, [data])

    const handleDelete = (e) => {
        removeCompanyRequest(e.target.value)
    }

    return (
        <div className='interventionsList'>
            <ListGroup>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Address</th>
                            <th>Description</th>
                            <th >Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0).reverse().map((e, i) => {
                            return (
                                <tr key={i} >
                                    <td  >{e.companyName} </td>
                                    <td >{e.address}</td>
                                    <td >{e.description}</td>
                                    <td>
                                    <Button
                                            variant='outline-dark'
                                            style={{ height: "35px" }}
                                            onClick={(e) => handleEdit(e)}
                                            value={e._id}
                                        >
                                            {'\ud83d\udd8a'}
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            onClick={handleDelete}
                                            variant='outline-danger'
                                            value={e._id}
                                        >X</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ListGroup>
        </div>
    );
}

export default UserForm;