import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import logout from '../api/logout';
import { AiOutlineLogout } from 'react-icons/ai'
import { SlMenu } from 'react-icons/sl';
import "./navbar.css"


function NavbarApp() {
    const expand = 'xl'
    const [show, setShow] = useState(false)
    const companyName = window.sessionStorage.getItem("companyName")
    const isAdmin = window.sessionStorage.getItem('role')
    const showButton = (isAdmin === "admin")
    const showDevButton = (isAdmin === "developer")
    const toggleOffCanvas = () => {
        setShow((show) => !show);
    };

    const message = showDevButton ? <p>Developer Mode</p> : (companyName ?
        <p>{`COMPANY :${companyName}`}</p>
        : <p>Please ask your admin for set your profile for a company</p>)

    return (
        <>
            <Navbar key={expand} container expand={expand} className="mb-3 navbarclass">
                <Container fluid >
                    <Navbar.Brand className='navtext' style={{ height: "80px" }} href="/">
                        <main className='navmain'>
                            <div className="navwrapper">
                                <img src='./images/logo-original50x60.jpg' alt='TWEMMAN' title='log' />
                                <h1>MAINTENAPP</h1>
                            </div>
                        </main>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}
                        onClick={toggleOffCanvas}
                        className='navbarclass navtext'
                    >
                        <SlMenu style={{ color: "white" }} />
                    </Navbar.Toggle>

                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                        show={show}
                        className='offcanvasClass'
                        onHide={toggleOffCanvas}
                    >
                        <Offcanvas.Header closeButton >
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                <p>MAINTENAPP</p>
                                {message}
                                {showButton && <p>Admin User</p>}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                {showDevButton && (window.location.pathname !== "/company")  && <Link onClick={toggleOffCanvas} className='btn btn-light m-1' to={`/company`}>
                                    Companies
                                </Link>}
                                {!showDevButton && (window.location.pathname !== "/production") && <Link onClick={toggleOffCanvas} className='btn btn-light m-1' to="/production" >
                                    PRODUCTION
                                </Link>}
                                {!showDevButton && (window.location.pathname !== "/interventions") && <Link onClick={toggleOffCanvas} className='btn btn-light m-1' to="/interventions" >
                                    INTERVENTIONS
                                </Link>}
                                {!showDevButton && (window.location.pathname !== "/requestinterventions") && <Link onClick={toggleOffCanvas} className='btn btn-light m-1' to="/requestinterventions" >
                                    DEMANDES D'INTERVENTIONS
                                </Link> }
                                {!showDevButton && (window.location.pathname !== "/cpu")  && <Link onClick={toggleOffCanvas} className='btn btn-light m-1' to="/cpu" >
                                    Capteurs
                                </Link>}
                                {!showDevButton && (window.location.pathname !== "/equipment") && <Link onClick={toggleOffCanvas} className='btn btn-light m-1' to="/equipment" >
                                    EQUIPMENT
                                </Link>}
                                {!showDevButton && (window.location.pathname !== "/controlePreventif") && <Link onClick={toggleOffCanvas} className='btn btn-light m-1' to="/controlePreventif" >
                                    Préventif
                                </Link>}
                                {showDevButton && (window.location.pathname !== "/users") && <Link onClick={toggleOffCanvas} className='btn btn-light m-1' to="/users" >
                                    USERS
                                </Link>}
                                <Link
                                    onClick={() => { toggleOffCanvas(); logout() }}
                                    className='btn m-1'
                                    to="/login">
                                    <AiOutlineLogout
                                        style={{ height: "30px", width: "30px", color: "white", backgroundColor: "gray" }}
                                    />
                                </Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>

                </Container>
            </Navbar>
        </>
    );
}

export default NavbarApp;