import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import readFilteredCPURequest from '../api/apiCPU/readCPURequest';
import readProd from '../api/apiProdReport/readProdReportsRequest';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const LineChart = () => {
    const navigate = useNavigate()

    const [nombre, setNombre] = useState(400)
    const [ratio, setRatio] = useState(2)

    const [trigger, setTrigger] = useState(0)
    const [dataCPU, setData] = useState([])
    const [dataCrible, setDataCrible] = useState([])
    const [_id, setId] = useState("")

    const isAdmin = window.sessionStorage.getItem('role')
    const companyName = window.sessionStorage.getItem('companyName')

    const functionData = (e) => { setData(e) }
    const functionDataCrible = (e) => { setDataCrible(e) }

    
    useEffect(() => {
        readProd()
            .then((e) => functionDataCrible(e));
    }, [trigger])

    useEffect(() => {
        readFilteredCPURequest()
            .then((e) => functionData(e));
    }, [trigger])

    const printedDATA = dataCPU.slice(-nombre).filter((value, i) => { return (i % ratio == 0) })
    const data = {

        labels: printedDATA.map((e) => e.Date.slice(5) + "@" + e.time.slice(0, -3)),
        datasets: [
            {
                label: 'C1 Droite',
                data: printedDATA.map((e) => e.Temperature_C1_D),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#ff0000',
            },
            {
                label: 'C1 Gauche',
                data: printedDATA.slice(-nombre).map((e) => e.Temperature_C1_G),
                fill: true,
                backgroudColor: '#ffffff',
                borderColor: '#990000',
            },
            {
                label: 'C2 Droite',
                data: printedDATA.slice(-nombre).map((e) => e.Temperature_C2_D),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#00aaff',
            },
            {
                label: 'C2 Gauche',
                data: printedDATA.slice(-nombre).map((e) => e.Temperature_C2_G),
                fill: true,
                backgroudColor: '#ffffff',
                borderColor: '#66ccff',
            },
        ],
    };

    const dataMoteur = {
        labels: printedDATA.map((e) => e.Date.slice(5) + "@" + e.time.slice(0, -3)),
        datasets: [
            {
                label: 'C1',
                data: printedDATA.map((e) => e.Courant_M26),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#ff981a',
            },
            {
                label: 'C2',
                data: printedDATA.map((e) => e.Courant_M30),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#66ccff',
            },
        ]

    }

    const nombreTemp = 400;
    const dataCribleChartF = dataCrible.slice(-nombreTemp).filter((e)=>e.poste == 3)
    const dataCribleChart = {
        labels: dataCribleChartF.map((e) => e.date),
        datasets: [
            // Crible 1
            {
                label: 'Cr1_M',
                data: dataCribleChartF.map((e) => e.cr1temp1),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#ff981a',
            },            
            {
                label: 'Cr1_L',
                data: dataCribleChartF.map((e) => e.cr1temp2),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#A52A2A',
            }, 
// Crible 2
            {
                label: 'Cr2_M',
                data: dataCribleChartF.map((e) => e.cr2temp1),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#00FFFF',
            },            
            {
                label: 'Cr2_L',
                data: dataCribleChartF.map((e) => e.cr2temp2),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#008B8B',
            },

// Crible 3
            {
                label: 'Cr3_M',
                data: dataCribleChartF.map((e) => e.cr3temp1),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#7FFF00',
            },            
            {
                label: 'Cr3_L',
                data: dataCribleChartF.map((e) => e.cr3temp2),
                fill: true,
                backgroudColor: '#0071bd',
                borderColor: '#006400',
            },                        
        ]

    }

    const dataMoteurCribleChart = {
        labels: dataCribleChartF.map((e) => e.date),
    datasets: [
        // Crible 1        
        {
            label: 'Cr1_3',
            data: dataCribleChartF.map((e) => e.cr1temp3),
            fill: true,
            backgroudColor: '#0071bd',
            borderColor: '#ff981a',
        },            
        {
            label: 'Cr1_4',
            data: dataCribleChartF.map((e) => e.cr1temp4),
            fill: true,
            backgroudColor: '#0071bd',
            borderColor: '#ff981a',
        },
// Crible 2          
        {
            label: 'Cr2_3',
            data: dataCribleChartF.map((e) => e.cr2temp3),
            fill: true,
            backgroudColor: '#0071bd',
            borderColor: '#00FFFF',
        },            
        {
            label: 'Cr2_4',
            data: dataCribleChartF.map((e) => e.cr2temp4),
            fill: true,
            backgroudColor: '#0071bd',
            borderColor: '#00FFFF',
        },

// Crible 3           
        {
            label: 'Cr3_3',
            data: dataCribleChartF.map((e) => e.cr3temp3),
            fill: true,
            backgroudColor: '#0071bd',
            borderColor: '#7FFF00',
        },            
        {
            label: 'Cr3_4',
            data: dataCribleChartF.map((e) => e.cr3temp4),
            fill: true,
            backgroudColor: '#0071bd',
            borderColor: '#7FFF00',
        },
        
    ]

}

    const options = {
        pointStyle: false,
        scales: {
            y: {
                scaleLabel: {
                    display: true,
                    labelString: "Value"
                },
                ticks: {
                    autoSkip: true,
                    suggestedMax : 50,
                }
            },
            x: {
                scaleLabel: {
                    display: true,
                    labelString: "Value"
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 24,
                }
            }
        }
    }

    return (
        <>
            <Form>
                <div className='d-flex gap-3 justify-content-center'>
                    <InputGroup>
                        <InputGroup.Text>Durée</InputGroup.Text>
                        <Form.Select
                            type='text'
                            name='poste'
                            onChange={(e) => setNombre(e.target.value) }
                        >
                            <option selected value="216" >1 jour</option>
                            <option value="432" >2 jours</option>
                            <option value="3500" >7 jours</option>
                            <option value="100000">afficher tout</option>
                        </Form.Select>
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text>Précision</InputGroup.Text>
                        <Form.Select
                            type='text'
                            name='poste'
                            onChange={(e) => setRatio(e.target.value)}
                        >
                            <option value="5" >générale</option>
                            <option selected value="2" >modéré</option>
                            <option value="1" >très précise</option>
                        </Form.Select>
                    </InputGroup>
                </div>
            </Form>
            <h2> Température Concasseurs</h2>
            <Line data={data} options={options} />
            <h2> Ampérage Concasseurs </h2>
            <Line data={dataMoteur} options={options} />
            <h2> Température Roulement Cribles </h2>
            <Line data={dataCribleChart} options={options} />
            <h2> Température Moteurs Cribles </h2>
            <Line data={dataMoteurCribleChart} options={options} />

        </>
    )
}

export default LineChart
