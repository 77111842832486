import React from 'react'
import './welcome.css'

const Welcome = () => {

    return (
        <div >

            <main>
                <div className="wrapper">
                    <span data-text="Mainten"></span>
                    <span data-text="App"></span>
                </div>
            </main>
        </div>
    )
}

export default Welcome