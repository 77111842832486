import { Button } from 'react-bootstrap'
import React from 'react'
import { Link, Navigate } from "react-router-dom"
import Navbar from '../../components/Navbar'
import ShowEquipment from '../../components/ShowEquipment'
import ShowCPU from '../../components/ShowCPU'
import Footer from '../../components/Footer'

function Home() {

  const isAdmin = window.sessionStorage.getItem('role')
  const showButton = (isAdmin == "admin")

  return (
    <div className='container'>
      <Navbar />
      <h1>Equipment List </h1>
      {showButton && <Button variant='info'>
        <Link style={{color:'white'}} to='addnew'>Add New Equipment</Link>
      </Button>}
      <ShowEquipment />
      <Footer/>
    </div>
  )
}

export default Home