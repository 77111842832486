import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import readCompanyRequest from '../api/apiCompany/readCompanyRequest';
import readUserRequest from '../api/apiUsers/readUserRequest';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'

import Accordion from 'react-bootstrap/Accordion';
import Footer from '../components/Footer';

function Home() {
  const [users, setUsers] = useState([])
  const [company, setCompany] = useState([])
  const companyName = window.sessionStorage.getItem("companyName")
  const username = window.sessionStorage.getItem("username")
  const firstName = window.sessionStorage.getItem("firstName")
  const role = window.sessionStorage.getItem("role")
  useEffect(() => {
    readUserRequest().then(res => setUsers(res))
    readCompanyRequest().then(res => setCompany(res[0]))
  }, [])
  return (
    <div className='container'>
      <Navbar />
      <h1 className='mb-5'>WELCOME {firstName} <br /> TO <br />MYCOMPANY APP</h1>

      <Card>
      <Card.Header as="h5">Manage Companies</Card.Header>
      <Card.Body>
        {/* <Card.Title>Special title treatment</Card.Title> */}
        <Card.Text>
        As the administrator of this web application, your role is to create a new 
            company for a new client, edit the details of an existing company or even
            delete a company from our database.
            But with all this abilities, you cannont access to any of the companies data.
        </Card.Text>
        <Button variant="info"><Link style={{color:"white"}} to='/company'>MyCompanies</Link></Button>
      </Card.Body>
    </Card>

    <Card>
      <Card.Header as="h5">Manage Users Accounts</Card.Header>
      <Card.Body>
        {/* <Card.Title>Special title treatment</Card.Title> */}
        <Card.Text>
        Managing the users and their access and to which company they are
            related is one of your responsabilities.
            Each new user registred cannot have access to any data unless you assign him 
            to one company with a specific role.
        </Card.Text>
        <Button variant="info"><Link style={{color:"white"}} to='/users'>MyUsers</Link></Button>
      </Card.Body>
    </Card>
    <Footer></Footer>
    </div>
  )
}

export default Home