import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const DatePicker = ({setDatePicker}) => {
    
    const handleCallback = (start, end)=> {
        setDatePicker(start._d, end._d)
    }

    const handleCancel = function(event, picker) {
        setDatePicker("","")
    }

    return (
        <div>
            <DateRangePicker
                initialSettings={{ 
                    startDate: new Date().getDate(), 
                    endDate: new Date().getDate()}}
                    onCallback={handleCallback}
                    onCancel={handleCancel}
            >
                <button>filter</button>
            </DateRangePicker>
        </div>
    )
}

export default DatePicker