import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import loginRequest from "../api/loginRequest";
import { useNavigate } from "react-router-dom";
import "./Login.css";

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import jwtDecode from "jwt-decode";
import Welcome from "./Welcome";

function Login() {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [passwordError, setpasswordError] = useState("");
    const [emailError, setemailError] = useState("");
    const [error, setError] = useState("");

    const loginSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        window.sessionStorage.clear()
        loginRequest(email, password)
            .then(({ token }) => {
                if (token) {
                    setLoading(false)
                    const decodedToken = jwtDecode(token)
                    token && window.sessionStorage.setItem('token', token);
                    window.sessionStorage.setItem('companyName', decodedToken.companyName);
                    window.sessionStorage.setItem('username', decodedToken.username);
                    window.sessionStorage.setItem('firstName', decodedToken.firstName);
                    window.sessionStorage.setItem('lastName', decodedToken.lastName);
                    window.sessionStorage.setItem('role', decodedToken.role);
                    decodedToken.role == "developer" ? navigate("/users") : navigate("/requestinterventions")
                } else {
                    setpasswordError("email erroné")
                    setLoading(false)

                }
            })
            .catch((error) => {
                setLoading(false)
                setpasswordError("mot de passe erronée")
                setError(error.message);
                // navigate('/login')
            });
    };

    return (

        <div className="login">
            {
                <div className="container login-container row d-flex">
                    <div className="col-lg lg-8 login-logo" >
                        <Welcome />
                    </div>

                    <div className="col-lg lg-4 login-input d-flex row">
                        <div>
                            <h1 className="login-title">

                            </h1>
                        </div>
                        <form id="loginform" onSubmit={loginSubmit}>

                            <div className="form-group mb-2">
                                <FloatingLabel
                                    controlId="EmailInput"
                                    label="Email"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="email"
                                        className="form-control"
                                        id="EmailInput"
                                        name="EmailInput"
                                        placeholder="Enter email"
                                        onChange={(event) => {setEmail(event.target.value); setpasswordError('')}}
                                    />
                                </FloatingLabel>
                                <small id="emailHelp" className="text-danger form-text">
                                    {emailError}
                                </small>
                            </div>

                            <div className="form-group mb-2">
                                <FloatingLabel
                                    controlId="InputPassword"
                                    label="Password"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="password"
                                        className="form-control"
                                        id="InputPassword"
                                        placeholder="Password"
                                        onChange={(event) => {setPassword(event.target.value) ; setpasswordError('')}}
                                    />
                                </FloatingLabel>
                                <small id="passworderror" className="text-danger bg-light form-text">
                                    {passwordError}
                                </small>
                            </div>
                            {
                                loading ?
                                    <button className="btn btn-secondary disabled w-100 mt-3 connecting">
                                        Connecting
                                    </button>
                                    :
                                    <button type="submit" className="btn btn-primary w-100 mt-3" href="/login">
                                        Login
                                    </button>
                            }

                            <p className="text-center mt-2 label">Not a member? <a href="register">Register</a></p>
                        </form>
                    </div>
                </div>

            }
        </div>
    );
}
export default Login;
