import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import DatePicker from './DatePicker';


import FilterByEquipment from '../views/requestinterventions/filters/FilterByEquipment'
import FilterByDoneBy from '../views/interventions/filters/FilterByDoneBy'
import FilterByDone from '../views/requestinterventions/filters/FilterByDone'
import FilterByRequestedBy from '../views/interventions/filters/FilterByRequestedBy'

// import readRequestInterventionRequest from '../api/apiRequestIntervention/readRequestInterventionRequest';
import readFilteredRequestInterventionsRequest from '../api/apiRequestIntervention/readFilteredRequestInterventionsRequest';
import removeRequestInterventionRequest from '../api/apiRequestIntervention/removeRequestInterventionRequest';
import update from '../api/apiRequestIntervention/updateRequestInterventionRequest'


function UserForm() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [filterDesc, setFilterDescription] = useState("")

    const [filterDoneBy, setFilterDoneBy] = useState("")
    const [filterRequestBy, setFilterRequestBy] = useState("")
    const [done, setDone] = useState("")

    const [_id, setId] = useState("")
    const [trigger, setTrigger] = useState(0)


    const isAdmin = window.sessionStorage.getItem('role')
    const companyName = window.sessionStorage.getItem('companyName')
    const showButton = (isAdmin == "admin")

    const handleEdit = (e) => {
        navigate(`edit/${e.target.value}`)
    }

    useEffect(() => {
        readFilteredRequestInterventionsRequest(companyName)
            .then(res => {setData(res); setLoading(false)});
    }, [trigger])


    const handleDelete = (e) => {
        removeRequestInterventionRequest(e.target.value)
            .then(res => setTrigger(trigger + 1))
        // window.location.reload(false);
    }

    const handleExecute = (e) => {
        navigate(`/interventions/addnew`, { state: { requestData: e } })
    }

    const filterFunction = (e) => {
        if (filterRequestBy !== "" && filterDoneBy == "") { return (e.requestedBy == filterRequestBy) }
        else if (filterRequestBy !== "" && filterDoneBy !== "") { return (e.requestedBy == filterRequestBy && e.doneBy == filterDoneBy) }
        else if (filterRequestBy == "" && filterDoneBy !== "") { return (e.doneBy == filterDoneBy) }
        else { return true }
    }

    const filterDone = (e) => {
        if (done !== "") { return e.isDone == (done == "done" ? true : false) }
        else { return true }
    }


    const filterDescription = (e) => {
        if (filterDesc !== "") { return e.description.toLowerCase().includes(filterDesc.toLowerCase()) }
        else { return true }
    }

    const filterDate = (e) => {
        // console.log(startDate.getTime())
        const dateParts = (e.date).split("/");
        const dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
        if (startDate !== "") {
            return (
                dateObject.getTime() > startDate.getTime()
                &&
                dateObject.getTime() < endDate?.getTime()
            )
        }
        return true
    }

    return (
        <div className='interventionsList'>
            {
                loading?
                <div className='connecting'>Loading</div>
                :
            
            <ListGroup>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Heure</th>
                            <th>Equipment</th>
                            <th>Demandé par</th>
                            <th>Exécuté par</th>
                            <th>Description</th>
                            <th>Etat</th>
                            {showButton && <th >Edit</th>}
                            {showButton && <th>Delete</th>}
                        </tr>
                        <tr>
                            <th><DatePicker setDatePicker={(s, e) => {
                                setStartDate(s)
                                setEndDate(e)
                            }} /></th>
                            <th></th>
                            <th><FilterByEquipment setData={(e) => setData(e)} /></th>
                            <th><FilterByRequestedBy setFilterRequestBy={(e) => setFilterRequestBy(e)} /></th>
                            <th><FilterByDoneBy setFilterDoneBy={(e) => setFilterDoneBy(e)} /></th>
                            <th><input onChange={(e) => setFilterDescription(e.target.value)}></input></th>
                            <th><FilterByDone setDone={(e) => setDone(e)} /></th>
                            {showButton && <th></th>}
                            {showButton && <th></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data.filter(filterFunction).filter(filterDone).filter(filterDescription).filter(filterDate).slice(0).reverse().map((e, i) => {
                            return (
                                <tr key={i} >
                                    <td  >{e.date} </td>
                                    <td >{e.time}</td>
                                    <td >{e.equipmentName}</td>
                                    <td >{e.requestedBy}</td>
                                    <td >{e.doneBy}</td>
                                    <td >{e.description}</td>
                                    <td style={{ color: "green", fontSize: "20px" }}>{e.isDone ?
                                        <span style={{ height: "40px" }}>&#10003;</span> :
                                        <Button
                                            variant='warning'
                                            style={{ height: "35px" }}
                                            onClick={() => handleExecute(e)}
                                            value={{ e }}
                                        >
                                            Exécuter
                                        </Button>
                                    }</td>
                                    {/* {showButton && <td >{e.companyName}</td>} */}
                                    {showButton && <td>
                                        <Button
                                            variant='outline-dark'
                                            style={{ height: "35px" }}
                                            onClick={(e) => handleEdit(e)}
                                            value={e._id}
                                        >
                                            {'\ud83d\udd8a'}
                                        </Button>
                                    </td>}
                                    {showButton && <td>
                                        <Button
                                            onClick={(e) => handleDelete(e)}
                                            variant='outline-danger'
                                            value={e._id}
                                        >X</Button>
                                    </td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ListGroup>
            }
        </div>
    );
}

export default UserForm;