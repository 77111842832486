import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import findOneUserRequest from '../../api/apiUsers/findOneUserRequest'
import updateUserRequest from '../../api/apiUsers/updateUserRequest'
import Navbar from '../../components/Navbar'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';

import readCompanyRequest from '../../api/apiCompany/readCompanyRequest'
import Footer from '../../components/Footer'

const EditUserPage = () => {
  const { id } = useParams()

  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [role, setRole] = useState('')
  const [companyName, setCompanyName] = useState("")
  const [companyList, setCompanyList] = useState([])

  useEffect(() => {
    findOneUserRequest(id)
      .then(res => {
        setEmail(res.email)
        setFirstName(res.firstName)
        setLastName(res.lastName)
        setUsername(res.username)
        setRole(res.role)
        setCompanyName(res.companyName)
      })
  }, [])

  useEffect(()=>{
    readCompanyRequest()
    .then(res => setCompanyList(res))
  },[])

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log("data in handlesubmit :",username)
    const data = {
      id, firstName, lastName, email, username, role, companyName
    }
    updateUserRequest(data)
      .then(res =>
        navigate('/users')
      )
  }
  return (
    <div className='container'>
      <Navbar />
      <h1>Edit Users Page</h1>
      <div >
        <Form className='d-flex flex-column' onSubmit={handleSubmit}>
          <InputGroup>
            <InputGroup.Text>First Name</InputGroup.Text>
            <Form.Control placeholder='edit First Name' onChange={(e) => setFirstName(e.target.value)} name='firstName' value={firstName ? firstName : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Last Name</InputGroup.Text>
            <Form.Control placeholder='edit Last Name' onChange={(e) => setLastName(e.target.value)} name='lastName' value={lastName ? lastName : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>@username</InputGroup.Text>
            <Form.Control placeholder='enter new username' onChange={(e) => setUsername(e.target.value)} name='username' value={username ? username : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Company Name</InputGroup.Text>
            <Form.Select
                aria-label="Default select example"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName?companyName:""}>
                <option>Select a company</option>
                {companyList.map((e, i) => {
                  return (
                    <option key={i} value={e.companyName}>
                      {e.companyName}
                    </option>
                  )
                })}
              </Form.Select>
            {/* <Form.Control placeholder='Comapny Name' name='companyName' onChange={(e) => setCompanyName(e.target.value)} value={companyName ? companyName : ""} /> */}
          </InputGroup>

          <div className="mb-3">
            <Form.Check
              inline
              type={'radio'}
              name="role"
              id="user"
              label="user"
              checked={role === 'user'}
              onChange={(e) => setRole(e.target.id)}
            />
            <Form.Check
              inline
              name="role"
              type={'radio'}
              label="admin"
              id="admin"
              checked={role === 'admin'}
              onChange={(e) => setRole(e.target.id)}
            />
            <Form.Check
              inline
              name="role"
              type={'radio'}
              label="developer"
              checked={role === 'developer'}
              id="developer"
              onChange={(e) => setRole(e.target.id)}
            />

          </div>
          <Button type='submit'>Update</Button>
        </Form>
      </div>
      <Footer/>
    </div>
  )
}

export default EditUserPage