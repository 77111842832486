import {API_URL} from '../config'

export default (data)=>{
    const token = window.sessionStorage.getItem('token')
    return fetch(`${API_URL}/updatecompany/${data.id}`,{
        method:'PUT',
        headers:{
            Authorization: `Bearer ${token}`,
            "Content-Type" : 'application/json'
        },
        body: JSON.stringify(data)
    })
    .then(response=>response.json())
}