import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import findOneCompanyequest from '../../api/apiCompany/findOneCompanyRequest'
import updateCompanyequest from '../../api/apiCompany/updateCompanyRequest'
import Navbar from '../../components/Navbar'

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/Footer'


const EditCompanyage = () => {
  const { id } = useParams()
  const [data, setData] = useState([])
  const [address, setAddress] = useState('')
  const [description, setDescription] = useState('')
  const [companyName, setCompanyName] = useState("")

  useEffect(() => {
    findOneCompanyequest(id)
      .then(res => {
        // setData(res)
        setAddress(res.address)
        setDescription(res.description)
        setCompanyName(res.companyName)
        console.log("data in useEffect :", data)
      })
  }, [])

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("company name in handlesubmit :",companyName)
    const data = {
      id,address,description,companyName
    }
    updateCompanyequest(data)
    navigate('/company')
  }
  return (
    <div className='container'>
      <Navbar />
      <h1>Edit CompanyPage</h1>
      <div >
      <Form className='d-flex flex-column m-2 gap-2' onSubmit={handleSubmit}>
          
          <InputGroup>
          <InputGroup.Text>Company Name</InputGroup.Text>
          <Form.Control 
          placeholder='Comapny Name' 
          name='companyName' 
          value={companyName?companyName:""}
          onChange={(e)=>setCompanyName(e.target.value)}
          />
          </InputGroup>
          
          <InputGroup>
          <InputGroup.Text>Company Address</InputGroup.Text>
          <Form.Control 
          placeholder='company address' 
          name='address' 
          value = {address?address:""}
          onChange={(e)=>setAddress(e.target.value)} 
          />
          </InputGroup>
          
          <InputGroup>
          <InputGroup.Text>Company Description</InputGroup.Text>
          <Form.Control 
          placeholder='Description of the company'
          name='description'
          value={description?description:""}
          onChange={(e)=>setDescription(e.target.value)} 
          />
          </InputGroup>
          <Button type='submit'>Submit</Button>
        </Form>
        {/* <form className='d-flex flex-column' onSubmit={handleSubmit}>

          <input placeholder='Comapny Name' name='companyName' onChange={(e) => setCompanyName(e.target.value)} value={companyName}/>
          <input placeholder='Address' name='address' onChange={(e) => setAddress(e.target.value)} value={address}/>
          <input placeholder='Description of company' onChange={(e) => setDescription(e.target.value)} name='description' value={description}/>
          <button type='submit'>Update</button>
        </form> */}
      </div>
      <Footer/>
    </div>
  )
}

export default EditCompanyage