import React, { useEffect, useState } from 'react'
import findOneCompanyByNameRequest from '../api/apiCompany/findOneCompanyByNameRequest'
import readFilteredUsersRequest from '../api/apiUsers/readFilteredUsersRequest'
import Navbar from '../components/Navbar'
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Footer from '../components/Footer';
import AddProdReportPage from './prodReports/AddProdReportPage';

function Home() {
  const [users, setUsers] = useState([])
  const [company, setCompany] = useState([])
  const companyName = window.sessionStorage.getItem("companyName")
  const username = window.sessionStorage.getItem("username")
  const firstName = window.sessionStorage.getItem("firstName")
  const role = window.sessionStorage.getItem("role")
  useEffect(() => {
    readFilteredUsersRequest(companyName).then(res => setUsers(res))
    findOneCompanyByNameRequest(companyName).then(res => setCompany(res[0]))
  }, [])
  return (
    <div className='container'>
      <Navbar />
      <h1 className='mb-5'>WELCOME {firstName} <br /> TO <br />MYCOMPANY APP</h1>
      {role=="developer"?<div>HELLO</div>:
      <div>
        <div className='
      d-flex flex-column align-items-start
      square border border-dark rounded
      p-2 m-2
      '>
          <h6><b>Company : </b>{company.companyName}</h6>
          <h6><b>Address :</b>{company.address}</h6>
          <h6><b>Company description :</b>{company.description}</h6>
        </div>
        <div className='mt-5'>
          <p>You Company Team</p>
          <ListGroup>
            <Table hover bordered size='sm' responsive='md' striped>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => {
                  return (
                    <tr key={user._id}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.email}</td>
                      <td>{user.role}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td>

                  </td>
                </tr>
              </tbody>
            </Table>
          </ListGroup>
        </div>
      </div>
      }
      <Footer/>
    </div>
  )
}

export default Home