import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import addProdReportRequest from '../../api/apiProdReport/addProdReportRequest'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';
import Navbar from '../../components/Navbar'
import './../views.css'
import Footer from '../../components/Footer';
import DateInput from '../../components/DateInput'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AddProdReportPage = () => {

  const current = new Date()
  const currentdate = current.toLocaleDateString()
  const currenttime = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  });

  const [data, setData] = useState({})

  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("companyName"))
  const [username, setUsername] = useState(window.sessionStorage.getItem("username"))
  const [date, setDate] = useState(currentdate)
  const [poste, setPoste] = useState("")
  const [hFtruck, sethFtruck] = useState("")
  const [hLtruck, sethLtruck] = useState("")
  const [truckQty, setTruckQty] = useState(0)
  const [pCounter, setPCounter] = useState(0)
  const [pCounterM, setPCounterM] = useState(0)

  const [c1CCounter, setc1CCounter] = useState(0)
  const [c1VCounter, setc1VCounter] = useState(0)
  const [c2CCounter, setc2CCounter] = useState(0)
  const [c2VCounter, setc2VCounter] = useState(0)

  const [stegPCounter, setstegPCounter] = useState(0)
  const [stegRCounter, setstegRCounter] = useState(0)

  const [comment, setComment] = useState("")
  const [pStoppageType, setpStoppageType] = useState("")
  const [pStoppageDuration, setpStoppageDuration] = useState("")

  const [g1220, setg1220] = useState(true)
  const [g48, setg48] = useState(false)
  const [g812, setg812] = useState(false)
  const [g2030, setg2030] = useState(false)
  const [dechet, setdechet] = useState(false)

  const [c1temp1, setc1temp1] = useState(0)
  const [c1temp2, setc1temp2] = useState(0)
  const [c1temp3, setc1temp3] = useState(0)
  const [c1temp4, setc1temp4] = useState(0)

  const [c2temp1, setc2temp1] = useState(0)
  const [c2temp2, setc2temp2] = useState(0)
  const [c2temp3, setc2temp3] = useState(0)
  const [c2temp4, setc2temp4] = useState(0)

  const [cr1temp1, setcr1temp1] = useState(0)
  const [cr1temp2, setcr1temp2] = useState(0)
  const [cr1temp3, setcr1temp3] = useState(0)
  const [cr1temp4, setcr1temp4] = useState(0)

  const [cr2temp1, setcr2temp1] = useState(0)
  const [cr2temp2, setcr2temp2] = useState(0)
  const [cr2temp3, setcr2temp3] = useState(0)
  const [cr2temp4, setcr2temp4] = useState(0)

  const [cr3temp1, setcr3temp1] = useState(0)
  const [cr3temp2, setcr3temp2] = useState(0)
  const [cr3temp3, setcr3temp3] = useState(0)
  const [cr3temp4, setcr3temp4] = useState(0)

  const [requestId, setRequestId] = useState("")

  const location = useLocation()
  const requestData = location.state?.requestData
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    addProdReportRequest({
      companyName,
      username,
      date,
      poste,

      hFtruck,
      hLtruck,
      truckQty,
      pCounter,
      pCounterM,

      c1CCounter,
      c1VCounter,
      c2CCounter,
      c2VCounter,

      stegPCounter,
      stegRCounter,

      comment,

      pStoppageType,
      pStoppageDuration,

      g1220, g48, g812, g2030, dechet,

      c1temp1,
      c1temp2,
      c1temp3,
      c1temp4,

      c2temp1,
      c2temp2,
      c2temp3,
      c2temp4,

      cr1temp1,
      cr1temp2,
      cr1temp3,
      cr1temp4,

      cr2temp1,
      cr2temp2,
      cr2temp3,
      cr2temp4,

      cr3temp1,
      cr3temp2,
      cr3temp3,
      cr3temp4,

    })
    navigate('/production')
  }

  return (
    <div className='container'>
      <Navbar />
      <h1>Rappport de poste de Production</h1>
      <div >
        <Form className='d-flex flex-column m-2 gap-2 wrap' onSubmit={handleSubmit}>
          <div className='d-flex gap-2 justify-content-center'>
            <div>
              <DateInput setDate={setDate} date={date} />

              <InputGroup>
                <InputGroup.Text>Reporté par</InputGroup.Text>
                <Form.Control placeholder='Done By' readOnly name='username' onChange={(e) => setUsername(e.target.value)} value={username ? username : ""} />
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Poste</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='poste'
                  onChange={(e) => setPoste(e.target.value)}
                >
                  <option value="" selected disabled hidden>Numéro de poste</option>
                  <option >1</option>
                  <option >2</option>
                  <option >3</option>
                </Form.Select>
              </InputGroup>
            </div>
            <div>
              <InputGroup>
                <InputGroup.Text>Premier Camion</InputGroup.Text>
                <Form.Control
                  placeholder='hFtruck'
                  type='time'
                  name='hFtruck'
                  onChange={(e) => sethFtruck(e.target.value)}
                  value={hFtruck ? hFtruck : ""} />
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Dernier Camion</InputGroup.Text>
                <Form.Control
                  placeholder='hLtruck'
                  type='time'
                  name='hLtruck'
                  onChange={(e) => sethLtruck(e.target.value)}
                  value={hLtruck ? hLtruck : ""} />
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Nbr de voyage</InputGroup.Text>
                <Form.Control
                  placeholder='truckQty'
                  type='number'
                  name='truckQty'
                  onChange={(e) => setTruckQty(e.target.value)}
                  value={truckQty ? truckQty : ""} />
              </InputGroup>
            </div>
          </div>

          <InputGroup>
            <InputGroup.Text>Cumul Prod / Jour</InputGroup.Text>
            <Form.Control
              placeholder='Cumul Production journalière'
              type='text'
              name='pCounter'
              onChange={(e) => setPCounter(e.target.value)}
              value={pCounter ? pCounter : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Cumul Prod / Mois</InputGroup.Text>
            <Form.Control
              placeholder='Cumul Production Mensuelle'
              type='text'
              name='pCounter'
              onChange={(e) => setPCounterM(e.target.value)}
              value={pCounterM ? pCounterM : ""} />
          </InputGroup>

          <div className='d-flex gap-3 justify-content-center'>
            <Form.Check
              type="switch"
              id="switch1220"
              label="G12/20"
              defaultChecked={true}
              // value={true}
              onChange={() => { setg1220(!g1220) }}
            />

            <Form.Check
              type="switch"
              id="switch48"
              label="G4/8"
              defaultChecked={false}
              // value={true}
              onChange={() => { setg48(!g48) }}
            />

            <Form.Check
              type="switch"
              id="switch812"
              label="G8/12"
              defaultChecked={false}
              // value={true}
              onChange={() => { setg812(!g812) }}
            />

            <Form.Check
              type="switch"
              id="switch2030"
              label="G20/30"
              defaultChecked={false}
              // value={true}
              onChange={() => { setg2030(!g2030) }}
            />

            <Form.Check
              type="switch"
              id="switchdechet"
              label="Dechet"
              defaultChecked={false}
              // value={true}
              onChange={() => { setdechet(!dechet) }}
            />
          </div>
          <Container fluid="xl">
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Text>C1 en Charge</InputGroup.Text>
                  <Form.Control
                    placeholder='c1CCounter'
                    type='number'
                    name='c1CCounter'
                    onChange={(e) => setc1CCounter(e.target.value)}
                    value={c1CCounter ? c1CCounter : ""} />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup>
                  <InputGroup.Text>C1 à vide</InputGroup.Text>
                  <Form.Control
                    placeholder='c1VCounter'
                    type='number'
                    name='c1VCounter'
                    onChange={(e) => setc1VCounter(e.target.value)}
                    value={c1VCounter ? c1VCounter : ""} />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Text>C2 en Charge</InputGroup.Text>
                  <Form.Control
                    placeholder='c2CCounter'
                    type='number'
                    name='c2CCounter'
                    onChange={(e) => setc2CCounter(e.target.value)}
                    value={c2CCounter ? c2CCounter : ""} />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup>
                  <InputGroup.Text>C2 à vide</InputGroup.Text>
                  <Form.Control
                    placeholder='c2VCounter'
                    type='number'
                    name='c2VCounter'
                    onChange={(e) => setc2VCounter(e.target.value)}
                    value={c2VCounter ? c2VCounter : ""} />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Text>STEG Active</InputGroup.Text>
                  <Form.Control
                    placeholder='stegPCounter'
                    type='number'
                    name='stegPCounter'
                    onChange={(e) => setstegPCounter(e.target.value)}
                    value={stegPCounter ? stegPCounter : ""} />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup>
                  <InputGroup.Text>STEG Reactive</InputGroup.Text>
                  <Form.Control
                    placeholder='stegRCounter'
                    type='number'
                    name='stegRCounter'
                    onChange={(e) => setstegRCounter(e.target.value)}
                    value={stegRCounter ? stegRCounter : ""} />
                </InputGroup>
              </Col>
            </Row>
          </Container>
          <InputGroup>
            <InputGroup.Text>Commentaires :</InputGroup.Text>
            <Form.Control
              placeholder='comment'
              type='text'
              name='comment'
              onChange={(e) => setComment(e.target.value)}
              value={comment ? comment : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Type d'arret de prod :</InputGroup.Text>
            <Form.Select
              type='text'
              placeholder='arrêt non planifié seulement'
              name='poste'
              onChange={(e) => setpStoppageType(e.target.value)}
            >
              <option value="" selected disabled hidden>Arrêt non planifié seulement</option>
              <option >Bourrage Trémie primaire</option>
              <option >Panne GJO</option>
              <option >Panne ECT</option>
              <option >Autre</option>
            </Form.Select>
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Durée d'arrêt non planifié :</InputGroup.Text>
            <Form.Control
              placeholder='arrêt non planifié seulement'
              type='time'
              name='pStoppageDuration'
              onChange={(e) => setpStoppageDuration(e.target.value)}
              value={pStoppageDuration ? pStoppageDuration : ""} />
          </InputGroup>

          <h3>Contrôle des Températures</h3>
          <table>
            <thead>
              <tr>
                <th>Equippement</th>
                <th>Point 1</th>
                <th>Point 2</th>
                <th>Point 3</th>
                <th>Point 4</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Crible 1</td>
                <td><input onChange={(e) => setcr1temp1(e.target.value)} /></td>
                <td><input onChange={(e) => setcr1temp2(e.target.value)} /></td>
                <td><input onChange={(e) => setcr1temp3(e.target.value)} /></td>
                <td><input onChange={(e) => setcr1temp4(e.target.value)} /></td>
              </tr>
              <tr>
                <td>Crible 2</td>
                <td><input onChange={(e) => setcr2temp1(e.target.value)} /></td>
                <td><input onChange={(e) => setcr2temp2(e.target.value)} /></td>
                <td><input onChange={(e) => setcr2temp3(e.target.value)} /></td>
                <td><input onChange={(e) => setcr2temp4(e.target.value)} /></td>
              </tr>
              <tr>
                <td>Crible 3</td>
                <td><input onChange={(e) => setcr3temp1(e.target.value)} /></td>
                <td><input onChange={(e) => setcr3temp2(e.target.value)} /></td>
                <td><input onChange={(e) => setcr3temp3(e.target.value)} /></td>
                <td><input onChange={(e) => setcr3temp4(e.target.value)} /></td>
              </tr>
            </tbody>
          </table>

          <Button type='submit'> Valider </Button>
        </Form>
      </div>
      <Footer />
    </div >
  )
}

export default AddProdReportPage