import { Button } from 'react-bootstrap'
import React from 'react'
import { Link, Navigate } from "react-router-dom"
import Navbar from '../../components/Navbar'
import ShowUsers from '../../components/ShowUsers'
import Footer from '../../components/Footer'

function Home() {

  return (
    <div className='container'>
      <Navbar />
      <h1>USERS</h1>
      <ShowUsers />
      <Footer/>
    </div>
  )
}

export default Home