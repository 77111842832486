import * as FileSaver from 'file-saver'
import { Button } from 'react-bootstrap';
import XLSX from 'sheetjs-style'

const ExportExcel = ({ excelData, fileName, data }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset-UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
                <Button className='m-3' variant='outline-secondary'
                    onClick={(e) => exportToExcel(fileName)}
                >Export {data} to Excel
                </Button>
        </>
    )


}

export default ExportExcel