import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { Link, Navigate } from "react-router-dom"
import ExportExcel from '../excelExport'
import readFilteredInterventionsRequest from '../api/apiIntervention/readFilteredInterventionsRequest';
import readFilteredRequestInterventionsRequest from '../api/apiRequestIntervention/readFilteredRequestInterventionsRequest';
import readProductionReports from '../api/apiProdReport/readProdReportsRequest'
import { GiBugleCall, GiAutoRepair } from 'react-icons/gi';
import { TbReport } from 'react-icons/tb';




const QuickButtons = () => {
    const [exportList, setExportList] = useState([])
    const [exportRequestsList, setExportRequestsList] = useState([])
    const [exportProdReports, setExportProdReports] = useState([])
    const interventionpage = (window.location.pathname == "/interventions")
    const requestinterventionpage = (window.location.pathname == "/requestinterventions")
    const companyName = window.sessionStorage.getItem('companyName')
    useEffect(() => {
        readFilteredInterventionsRequest(companyName)
            .then((res) => { setExportList(res) })

        readFilteredRequestInterventionsRequest(companyName)
            .then((res) => { setExportRequestsList(res) })

        readProductionReports(companyName)
            .then((res) => { setExportProdReports(res) })
    }, [])
    // let pathname = (window.location.pathname)
    // console.log(pathname)
    return (
        <div >
            <div className='d-flex flex-row justify-content-center'>
                <Button variant="alert" className='m-1'>
                    <Link style={{ color: 'white' }} to='/production/addnew'>
                        <TbReport style={{ height: "30px", width: "30px", color: "white" }} />
                        <p className='icons'>Nouveau rapport de prod</p>
                    </Link>
                </Button>
                <Button variant="alert" className='m-1'>
                    <Link style={{ color: 'white' }} to='/requestinterventions/addnew'>
                        <GiBugleCall style={{ height: "30px", width: "30px", color: "white" }} />
                        <p className='icons'>Demande d'intervention</p>
                    </Link>
                </Button>
                <Button variant="alert" className='m-1'>
                    <Link style={{ color: 'white' }} to='/interventions/addnew'>
                        <GiAutoRepair style={{ height: "30px", width: "30px", color: "white" }} />
                        <p className='icons'>Saisie nouvelle intervention</p>
                    </Link>
                </Button>

                {interventionpage && <Button variant="info" className='m-1 bascule'>
                    <Link style={{ color: 'white' }} to='/requestinterventions'>Voir les demandes</Link>
                </Button>}
                {requestinterventionpage && <Button variant="info" className='m-1 bascule'>
                    <Link style={{ color: 'white' }} to='/interventions'>Voir les interventions</Link>
                </Button>}
            </div>
            <div className='m-2'>
                <ExportExcel excelData={exportRequestsList} fileName={"Requests Excel Export"} data={'requests'} />
                <ExportExcel excelData={exportList} fileName={"Interventions Excel Export"} data={'interventions'} />
                <ExportExcel excelData={exportProdReports} fileName={"Production Reports Export"} data={'productionReports'} />
            </div>
        </div>
    )
}

export default QuickButtons