import {API_URL} from '../config'

export default async (companyName)=>{
    const token = window.sessionStorage.getItem('token')
    const response = await fetch(`${API_URL}/findOnecompanybyname/${companyName}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
        }
    })
    return response.json()
}

