import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import findOneRequestInterventionRequest from '../../api/apiRequestIntervention/findRequestOneInterventionRequest'
import updateRequestInterventionRequest from '../../api/apiRequestIntervention/updateRequestInterventionRequest'
import readFilteredEquipmentRequest from '../../api/apiEquipment/readFilteredEquipmentRequest'

import Navbar from '../../components/Navbar'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/Footer'
import DateInput from '../../components/DateInput'


const EditInterventionPage = () => {
  const { id } = useParams()
  const [data, setData] = useState([])
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [equipmentName, setEquipmentName] = useState('')
  const [requestedBy, setRequestedBy] = useState(window.sessionStorage.getItem("username"))
  const [doneBy, setDoneBy] = useState('')
  const [description, setDescription] = useState('')
  const [isDone, setIsDone] = useState()
  const [equipmentList, setEquipmentList] = useState([])
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("companyName"))

  useEffect(() => {
    findOneRequestInterventionRequest(id)
      .then(res => {
        // setData(res)
        setDate(res.date)
        setTime(res.time)
        setEquipmentName(res.equipmentName)
        setRequestedBy(res.requestedBy)
        setDescription(res.description)
        setIsDone(res.isDone)
        setCompanyName(res.companyName)
        console.log("data in useEffect :", data)
      })
    readFilteredEquipmentRequest(companyName)
      .then(res => {
        // console.log("filtered list : ",res)
        setEquipmentList(res)
      })
  }, [])

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("data in handlesubmit :", equipmentName)
    const data = {
      id, date, time, equipmentName, requestedBy, doneBy, description, isDone, companyName
    }
    updateRequestInterventionRequest(data)
    navigate('/requestinterventions')
  }
  return (
    <div className='container'>
      <Navbar />
      <h1>Edit A Request</h1>
      <div >
        <Form className='d-flex flex-column m-2 gap-2' onSubmit={handleSubmit}>

          <DateInput setDate={setDate} date ={date} />

          <InputGroup>
            <InputGroup.Text>Time</InputGroup.Text>
            <Form.Control
              placeholder='time'
              name='time'
              type="time"
              onChange={(e) => setTime(e.target.value)}
              value={time ? time : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Equipment</InputGroup.Text>
            <Form.Select aria-label="Default select example" value={equipmentName ? equipmentName : ""} onChange={(e) => setEquipmentName(e.target.value)}>
              <option>Select an equipment</option>
              {equipmentList.map((e, i) => {
                return (
                  <option key={i} value={e.reference}>
                    {e.reference}
                  </option>
                )
              })}
            </Form.Select>
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Requested By</InputGroup.Text>
            <Form.Control
              placeholder='Requested By'
              readOnly
              name='requestedBy'
              // onChange={(e) => setRequestedBy(e.target.value)} 
              value={requestedBy ? requestedBy : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Description</InputGroup.Text>
            <Form.Control
              placeholder='Description of the task'
              onChange={(e) => setDescription(e.target.value)}
              name='description'
              value={description ? description : ""} />
          </InputGroup>
          
          <Button type='submit'>Update</Button>
        </Form>
      </div>
      <Footer />
    </div>
  )
}

export default EditInterventionPage