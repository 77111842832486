import React from 'react'

const Footer = () => {
    var phantom = {
        display: 'block',
        padding: '20px',
        height: '60px',
        width: '100%',
    }
    return (
        <div className='text-center text-lg-left bg-light '>
            <div style={phantom}></div>
            <div className='text-center p-2 fixed-bottom' style={{
                backgroundColor: '#E5E5E5',
                height: "45px",
                position: "fixed"
            }}>
                &copy; 2023 Copyright : Mellef_M.A
            </div></div>
    )
}

export default Footer