import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import addInterventionRequest from '../../api/apiIntervention/addInterventionRequest'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';
import Navbar from '../../components/Navbar'
import './../views.css'
import readFilteredEquipmentRequest from '../../api/apiEquipment/readFilteredEquipmentRequest'
import updateRequestInterventionRequest from '../../api/apiRequestIntervention/updateRequestInterventionRequest';
import Footer from '../../components/Footer';
import DateInput from '../../components/DateInput'

const AddInterventionPage = () => {


  const current = new Date()
  const currentdate = current.toLocaleDateString()
  const currenttime = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  });

  const [data, setData] = useState({})
  const [date, setDate] = useState(currentdate)
  const [time, setTime] = useState(currenttime)
  const [equipmentName, setEquipmentName] = useState("")
  const [doneBy, setDoneBy] = useState(window.sessionStorage.getItem("username"))
  const [description, setDescription] = useState("")
  const [stoppage, setStoppage] = useState("")
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("companyName"))
  const [requestId, setRequestId] = useState("")
  const [requestedBy, setRequestedBy] = useState("")
  const [reqDescription, setReqDescription] = useState("")
  const [isDone, setIsDone] = useState(true)

  const [equipmentList, setEquipmentList] = useState([])
  useEffect(() => {
    readFilteredEquipmentRequest(companyName)
      .then(res => {
        setEquipmentList(res)
      })
  }, [equipmentList.length])

  // data from request intervention page
  const location = useLocation()
  const requestData = location.state?.requestData
  // console.log("request DATA :", requestData)

  useEffect(() => {
    if (requestData) {
      setEquipmentName(requestData.equipmentName);
      setRequestedBy(requestData.requestedBy);
      setRequestId(requestData.id);
      setReqDescription("requestData.description")
    }
  }, [])

  // ______
  const previousSentData = useRef({})
  useEffect(() => {
    setData({ date, time, equipmentName, doneBy, description, stoppage, companyName, requestId, requestedBy, reqDescription, isDone })
    // console.log('sent data : ', data)
    previousSentData.current = data
  }, [previousSentData])




  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    if (requestData) {
      updateRequestInterventionRequest({
        date: requestData.date,
        time: requestData.time,
        equipmentName,
        requestedBy: requestData.requestedBy,
        doneBy: data.doneBy,
        taskDescription: data?.description,
        description: requestData.description,
        isDone: true,
        companyName,
        id: requestData._id
      })
    }

    addInterventionRequest({
      date,
      time,
      equipmentName,
      doneBy: data.doneBy,
      description,
      stoppage,
      companyName,
      requestId: requestData?._id,
      requestedBy: requestData?.requestedBy,
      reqDescription: requestData?.description
    })
    console.log(reqDescription)
    navigate('/interventions')
  }

  return (
    <div className='container'>
      <Navbar />
      <h1>Ajouter une intervention</h1>
      <div >
        <Form className='d-flex flex-column m-2 gap-2' onSubmit={handleSubmit}>
        <DateInput setDate={setDate} date ={date} />

          <InputGroup>
            <InputGroup.Text>Heure</InputGroup.Text>
            <Form.Control placeholder='L heure de l intervention' type='time' name='time' onChange={(e) => setTime(e.target.value)} value={time ? time : ""} />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Réalisé par</InputGroup.Text>
            <Form.Control placeholder='Done By' readOnly name='doneBy' onChange={(e) => setDoneBy(e.target.value)} value={doneBy ? doneBy : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Equippement</InputGroup.Text>
            {requestData ?
              <Form.Control placeholder='Equipment' readOnly name='equipmentName' value={requestData.equipmentName} /> :
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setEquipmentName(e.target.value)}
                value={equipmentName ? equipmentName : ""}>
                <option>Choisir l'équippement</option>
                {equipmentList.map((e, i) => {
                  return (
                    <option key={i} value={e.reference}>
                      {e.reference}
                    </option>
                  )
                })}
              </Form.Select>}
          </InputGroup>

          {requestData ?
            <InputGroup>
              <InputGroup.Text>Demande</InputGroup.Text>
              <Form.Control placeholder='Description of the task' readOnly name='reqtaskdescription' value={requestData.description} />
            </InputGroup>
            : ""}

          <InputGroup>
            <InputGroup.Text>Description</InputGroup.Text>
            <Form.Control placeholder='Description des travaux exécutés' onChange={(e) => setDescription(e.target.value)} name='description' value={description ? description : ""} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Durée arrêt</InputGroup.Text>
            <Form.Control placeholder='durée de l arrêt de production à CAUSE de l intervention' type="time" name="stoppage" onChange={(e) => setStoppage(e.target.value)} value={stoppage ? stoppage : ""} />
          </InputGroup>
          <Button type='submit'> Saisie</Button>
        </Form>
      </div>
      <Footer/>
    </div>
  )
}

export default AddInterventionPage