import { Button } from 'react-bootstrap'
import React from 'react'
import { Link, Navigate } from "react-router-dom"
import Navbar from '../../components/Navbar'
import ShowRequestInterventions from '../../components/ShowRequestInterventions'
import QuickButtons from '../../components/QuickButtons'
import Footer from '../../components/Footer'

const companyName = window.sessionStorage.getItem('companyName')

function Home() {

  return (
    <div className='container'>
      <Navbar />
      <h1>LISTE DES DEMANDES D'INTERVENTION</h1>
      <QuickButtons />
      <ShowRequestInterventions />
      <Footer/>
    </div>
  )
}

export default Home