import {API_URL} from '../config'

export default (id)=>{
    const token = window.sessionStorage.getItem('token')
    return fetch(`${API_URL}/removecompany/${id}`,{
        method:'DELETE',
        headers:{
            Authorization: `Bearer ${token}`,
            "Content-Type" : 'application/json'
        }
    })
}