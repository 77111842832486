import React from 'react'
import Form from 'react-bootstrap/Form';

const Filters = ({ setDone }) => {

  const handleSelect = (e) => {
    setDone(e)
  }

  return (
    <div>
      <Form.Select aria-label="Done"
        onChange={(e) => handleSelect(e.target.value)}
        // value={selectEquipment}
      >
        <option value={""}>Filter</option>
        {["done", "not yet"].map((e, i) => {
          return (

            <option key={i} value={e} >
              {e}
            </option>

          )
        })}
      </Form.Select>

    </div>
  )
}

export default Filters