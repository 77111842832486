import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import registerRequest from "../api/registerRequest";
import { useNavigate } from "react-router-dom";
import findOneUserByEmailRequest from "../api/apiUsers/findOneUserByEmailRequest";
import Welcome from "./Welcome";
import "./Login.css";

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

function Register() {
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [passwordError, setpasswordError] = useState("");
    const [emailError, setemailError] = useState("");
    const [error, setError] = useState("");

    const user = {
        firstName,
        lastName,
        username,
        email,
        password
    }

    // const handleValidation = (event) => {
    //     let formIsValid = true;

    //     if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{1,3}$/)) {
    //         formIsValid = false;
    //         setemailError("Email Not Valid");
    //         return false;
    //     } else {
    //         setemailError("");
    //         formIsValid = true;
    //     }

    //     if (!password.match(/^[a-zA-Z]{8,22}$/)) {
    //         formIsValid = false;
    //         setpasswordError(
    //             "Only Letters and length must best min 8 Chracters and Max 22 Chracters"
    //         );
    //         return false;
    //     } else {
    //         setpasswordError("");
    //         formIsValid = true;
    //     }

    //     return formIsValid;
    // };
    const navigate = useNavigate()
    const loginSubmit = (e) => {
        e.preventDefault();
        findOneUserByEmailRequest(email).then(res => console.log(res))
        // handleValidation();
        registerRequest(firstName, lastName, username, email, password)
            .then((res) => {
                if (res.status == "user exists") {
                    setemailError("This email exists already.")
                    return
                }
                setFirstName("");
                setLastName("");
                setEmail("");
                setUsername("");
                setPassword('');
                navigate("/login")
            })
            .catch((error) => {
                setError(error.message);
            });

    };

    return (
        <div className="login">
            <div className="container login-container row d-flex ">

                <div className="col-lg lg-8">
                    <Welcome />
                </div>

                <div className="col-lg lg-4 login-input d-flex row mb-4">


                    <div>
                        <h1 className="login-title">
                            New User
                        </h1>
                    </div>

                    <form className="d-flex flex-column gap-4" id="registerForm" onSubmit={loginSubmit}>
                        <div className="d-flex gap-2 w-100">

                            <FloatingLabel
                                controlId="firstnameInput"
                                label="First Name"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    value={firstName}
                                    className="form-control"
                                    id="firstnameInput"
                                    name="firstnameInput"
                                    aria-describedby="firstnameHelp"
                                    placeholder="Firstname"
                                    onChange={(event) => setFirstName(event.target.value)}
                                />
                            </FloatingLabel>

                            <FloatingLabel
                                controlId="LastnameInput"
                                label="Last Name"
                            >
                                <Form.Control
                                    type="text"
                                    value={lastName}
                                    className="form-control"
                                    id="LastnameInput"
                                    name="LastnameInput"
                                    aria-describedby="LastnameHelp"
                                    placeholder="Lastname"
                                    onChange={(event) => setLastName(event.target.value)}
                                />
                            </FloatingLabel>
                        </div>

                        <div className="form-group">
                            <FloatingLabel
                                controlId="UsernameInput"
                                label="userame"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="text"
                                    value={username}
                                    className="form-control"
                                    id="UsernameInput"
                                    name="UsernameInput"
                                    aria-describedby="usernameHelp"
                                    placeholder="Enter username"
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                            </FloatingLabel>
                        </div>

                        <div className="form-group">
                            <FloatingLabel
                                controlId="EmailInput"
                                label="Email"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="email"
                                    value={email}
                                    className="form-control"
                                    id="EmailInput"
                                    name="EmailInput"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter email"
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </FloatingLabel>
                            <small id="emailHelp" className="text-light form-text">
                                {emailError}
                            </small>
                        </div>

                        <div className="form-group">
                            <FloatingLabel
                                controlId="InputPassword"
                                label="Password"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="password"
                                    value={password}
                                    className="form-control"
                                    id="InputPassword"
                                    placeholder="Password"
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </FloatingLabel>
                        </div>

                        <div className="form-group">
                            <FloatingLabel
                                controlId="InputConfirmPassword"
                                label="Confirm Password"
                                className="mb-3"
                            >
                                <Form.Control
                                    type="password"
                                    value={confirmpassword}
                                    className="form-control"
                                    id="InputConfirmPassword"
                                    placeholder="Confirm your Password"
                                    onChange={(event) => {
                                        setConfirmPassword(event.target.value)
                                        if (event.target.value == password) {
                                            setpasswordError("")
                                        } else {
                                            setpasswordError("the two passwords don't match")
                                        }
                                    }}
                                />
                            </FloatingLabel>
                            <small id="passworderror" className="text-light  form-text">
                                {passwordError}
                            </small>
                        </div>

                        <button type="submit" className="btn btn-primary" href="/login">
                            Register
                        </button>
                        <div style={{ color: "white" }}>
                            already registred ? <a href="/login">Login</a>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    );
}
export default Register;
