import React from 'react'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import QuickButtons from '../../components/QuickButtons'
import ShowProdReports from '../../components/ShowProdReports'

const companyName = window.sessionStorage.getItem('companyName')

function Home() {

  return (
    <div className='container'>
      <Navbar />
      <h1>TABLEAU DE BORD PRODUCTION</h1>
      <QuickButtons/>
      <ShowProdReports />
      <Footer/>
    </div>
  )
}

export default Home