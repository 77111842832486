import React from 'react'
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import readFilteredUsersRequest from '../../../api/apiUsers/readFilteredUsersRequest';
import filterInterventionsByDoerRequest from '../../../api/apiIntervention/filterInterventionsByDoerRequest';


const Filters = ({ setFilterRequestBy }) => {

  const companyName = window.sessionStorage.getItem('companyName')

  const [users, setUsers] = useState([])
  const [selectDoer, setSelectDoer] = useState("")

  useEffect(() => {
    readFilteredUsersRequest(companyName)
      .then(res => { setUsers(res) });
  }, [])

  const handleSelect = (e) => {
    setFilterRequestBy(e)
    // console.log(e)
  }

  return (
    <div>
      <Form.Select aria-label="User"
        onChange={(e) => handleSelect(e.target.value)}
        // value={selectDoer}
      >
        <option value={""}>Filter</option>
        {users.map((e, i) => {
          return (

            <option key={i} value={e.username} >
              {e.username}
            </option>

          )
        })}
      </Form.Select>
    </div>
  )
}

export default Filters