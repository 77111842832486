import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import addCompanyRequest from '../../api/apiCompany/addCompanyRequest'
import Navbar from '../../components/Navbar'

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/Footer';


const AddCompanyPage = () => {

  const [address, setAddress] = useState("")
  const [description, setDescription] = useState("")
  const [companyName, setCompanyName] = useState("")

  const data = {
    address,description,companyName
  }

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    navigate('/company')
    addCompanyRequest(data)
  }
  return (
    <div className='container'>
      <Navbar/>
      <h1>Add a new company</h1>
      <div >
        <Form className='d-flex flex-column m-2 gap-2' onSubmit={handleSubmit}>
          
          <InputGroup>
          <InputGroup.Text>Company Name</InputGroup.Text>
          <Form.Control 
          placeholder='Comapny Name' 
          name='companyName' 
          value={companyName?companyName:""}
          onChange={(e)=>setCompanyName(e.target.value)}
          />
          </InputGroup>
          
          <InputGroup>
          <InputGroup.Text>Company Address</InputGroup.Text>
          <Form.Control 
          placeholder='company address' 
          name='address' 
          value = {address?address:""}
          onChange={(e)=>setAddress(e.target.value)} 
          />
          </InputGroup>
          
          <InputGroup>
          <InputGroup.Text>Company Description</InputGroup.Text>
          <Form.Control 
          placeholder='Description of the company'
          name='description'
          value={description?description:""}
          onChange={(e)=>setDescription(e.target.value)} 
          />
          </InputGroup>
          <Button type='submit'>Submit</Button>
        </Form>
      </div>
      <Footer/>
    </div>
  )
}

export default AddCompanyPage