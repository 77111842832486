import { useEffect, useState } from 'react';
// import "bootstrap/dist/css/bootstrap.min.css";

import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import FilterByEquipment from '../views/interventions/filters/FilterByEquipment'
import FilterByDoneBy from '../views/interventions/filters/FilterByDoneBy'
import FilterByRequestedBy from '../views/interventions/filters/FilterByRequestedBy'

// import readInterventionRequest from '../api/apiIntervention/readInterventionRequest';
import readFilteredInterventionsRequest from '../api/apiIntervention/readFilteredInterventionsRequest';
import removeInterventionRequest from '../api/apiIntervention/removeInterventionRequest';
import DatePicker from './DatePicker';

function UserForm() {
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [datePicker, setDatePicker] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [filterDesc, setFilterDescription] = useState("")
    const [filterDoneBy, setFilterDoneBy] = useState("")
    const [filterRequestBy, setFilterRequestBy] = useState("")
    const [_id, setId] = useState("")
    const [trigger, setTrigger] = useState(0)

    const isAdmin = window.sessionStorage.getItem('role')
    const companyName = window.sessionStorage.getItem('companyName')
    const username = window.sessionStorage.getItem('username')
    const showButton = (isAdmin == "admin")


    const handleEdit = (e) => {
        navigate(`edit/${e.target.value}`)
    }

    const handleDelete = (e) => {
        removeInterventionRequest(e.target.value)
            .then(res => setTrigger(trigger + 1))
    }

    const functionData = (e) => { setData(e) }

    useEffect(() => {
        readFilteredInterventionsRequest(companyName)
            .then(res => { functionData(res); setLoading(false) });
    }, [trigger])

    const filterFunction = (e) => {
        if (filterRequestBy !== "" && filterDoneBy == "") { return (e.requestedBy == filterRequestBy) }
        else if (filterRequestBy !== "" && filterDoneBy !== "") { return (e.requestedBy == filterRequestBy && e.doneBy == filterDoneBy) }
        else if (filterRequestBy == "" && filterDoneBy !== "") { return (e.doneBy == filterDoneBy) }
        else { return true }
    }

    const filterDescription = (e) => {
        if (filterDesc !== "") { return e.description.toLowerCase().includes(filterDesc.toLowerCase()) }
        else { return true }
    }

    const filterDate = (e) => {
        // console.log(startDate.getTime())
        const dateParts = (e.date).split("/");
        const dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
        if (startDate !== "") {
            return (
                dateObject.getTime() > startDate.getTime()
                &&
                dateObject.getTime() < endDate?.getTime()
            )
        }
        return true
    }


    return (
        <div className='interventionsList'>
            {
                loading ?
                    <div className='connecting'>Loading</div>
                    :
                    <ListGroup>
                        <Table hover bordered size='sm' responsive='md' striped>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Heure</th>
                                    <th>Equipment</th>
                                    <th>Description de la demande</th>
                                    <th>Tache exécutée</th>
                                    <th>Executé par</th>
                                    <th>Temps d'arrêt</th>
                                    <th>Demandé par</th>
                                    {showButton && <th >Edit</th>}
                                    {showButton && <th>Delete</th>}
                                </tr>
                                <tr>
                                    <th><DatePicker setDatePicker={(s, e) => {
                                        setStartDate(s)
                                        setEndDate(e)
                                    }} /></th>
                                    <th></th>
                                    <th><FilterByEquipment setData={(e) => setData(e)} /></th>
                                    <th></th>
                                    <th><input onChange={(e) => setFilterDescription(e.target.value)}></input></th>
                                    <th><FilterByDoneBy setFilterDoneBy={(e) => setFilterDoneBy(e)} /></th>
                                    <th></th>
                                    <th><FilterByRequestedBy setFilterRequestBy={(e) => setFilterRequestBy(e)} /></th>
                                    {showButton && <th></th>}
                                    {showButton && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.filter(filterFunction).filter(filterDescription).filter(filterDate).slice(0).reverse().map((e, i) => {
                                    return (
                                        <tr key={i} >
                                            <td  >{e.date} </td>
                                            <td >{e.time}</td>
                                            <td >{e.equipmentName}</td>
                                            <td >{e.reqDescription}</td>
                                            <td >{e.description}</td>
                                            <td >{e.doneBy}</td>
                                            <td >{e.stoppage}</td>
                                            <td >{e.requestedBy}</td>
                                            {showButton && <td>
                                                <Button
                                                    variant='outline-dark'
                                                    style={{ height: "35px" }}
                                                    onClick={(e) => handleEdit(e)}
                                                    value={e._id}
                                                >
                                                    {'\ud83d\udd8a'}
                                                </Button>
                                            </td>}
                                            {showButton && <td>
                                                <Button
                                                    onClick={handleDelete}
                                                    variant='outline-danger'
                                                    value={e._id}
                                                >X</Button>
                                            </td>}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </ListGroup>
            }
        </div>
    );
}

export default UserForm;