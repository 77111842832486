import { useEffect, useState } from 'react';
// import "bootstrap/dist/css/bootstrap.min.css";

import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';

import readProdReportsRequest from '../api/apiProdReport/readProdReportsRequest';
import removeProdReportRequest from '../api/apiProdReport/removeProdReportRequest';
import DatePicker from './DatePicker';

function UserForm() {
    const navigate = useNavigate()

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [_id, setId] = useState("")
    const [trigger, setTrigger] = useState(0)

    const isAdmin = window.sessionStorage.getItem('role')
    const companyName = window.sessionStorage.getItem('companyName')
    const showButton = (isAdmin == "admin")


    const handleEdit = (e) => {
        navigate(`edit/${e.target.value}`)
    }

    const handleDelete = (e) => {
        removeProdReportRequest(e.target.value)
            .then(res => setTrigger(trigger + 1))
    }

    const functionData = (e) => { setData(e) }

    useEffect(() => {
        readProdReportsRequest(companyName)
            .then(res => { functionData(res); setLoading(false) });
    }, [trigger])

    const filterDate = (e) => {
        // console.log(startDate.getTime())
        const dateParts = (e.date).split("/");
        const dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
        if (startDate !== "") {
            return (
                dateObject.getTime() >= startDate.getTime()
                &&
                dateObject.getTime() <= endDate?.getTime()
            )
        }
        return true
    }

    const filteredData = data?.filter(filterDate).slice(0)

    const sortedData = filteredData.sort((a, b) => {
        const dateA = new Date(...a.date.split('/').reverse());
        const dateB = new Date(...b.date.split('/').reverse());
        return dateA - dateB;
    })

    return (
        <div className='interventionsList'>
            {
                loading ?
                    <div className='connecting'>Loading</div>
                    :
                    <ListGroup>
                        <Table hover bordered size='sm' responsive='md' striped>
                            <thead>
                                <tr>
                                    <th>
                                        <div>Date</div>
                                        <div><DatePicker setDatePicker={(s, e) => {
                                            setStartDate(s)
                                            setEndDate(e)
                                        }} /></div>
                                    </th>
                                    <th >Poste</th>
                                    <th >Opérateur</th>
                                    <th >Premier Camion</th>
                                    <th >Dernier camion</th>
                                    <th >Nbr de voyage</th>
                                    <th >Cumul Prod/J</th>
                                    <th >Cumul Prod/M</th>
                                    <th >Compteur STEG (A)</th>
                                    <th >Compteur STEG (R)</th>
                                    <th>Arrêt</th>
                                    <th>Type d'arrêt</th>
                                    <th>Commentaire</th>
                                    {showButton && <th >Edit</th>}
                                    {showButton && <th>Delete</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData?.reverse().map((e, i) => {
                                    return (
                                        <tr key={i}  >
                                            <td >{e.date} </td>
                                            <td >{e.poste} </td>
                                            <td >{e.username} </td>
                                            <td >{e.hFtruck} </td>
                                            <td >{e.hLtruck} </td>
                                            <td >{e.truckQty}</td>
                                            <td >{e.pCounter}</td>
                                            <td >{e.pCounterM}</td>
                                            <td >{e.stegPCounter}</td>
                                            <td >{e.stegRCounter}</td>
                                            <td >{e.pStoppageDuration}</td>
                                            <td >{e.pStoppageType}</td>
                                            <td >{e.comment}</td>
                                            {showButton && <td>
                                                <Button
                                                    variant='outline-dark'
                                                    style={{ height: "35px" }}
                                                    onClick={(e) => handleEdit(e)}
                                                    value={e._id}
                                                >
                                                    {'\ud83d\udd8a'}
                                                </Button>
                                            </td>}
                                            {showButton && <td>
                                                <Button
                                                    onClick={handleDelete}
                                                    variant='outline-danger'
                                                    value={e._id}
                                                >X</Button>
                                            </td>}
                                        </tr>
                                    )
                                })
                                }

                            </tbody>
                        </Table>
                    </ListGroup>
            }
        </div>
    );
}

export default UserForm;