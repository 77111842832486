import { Button } from 'react-bootstrap'
import React from 'react'
import { Link} from "react-router-dom"
import Navbar from '../../components/Navbar'
import ShowCompanies from '../../components/ShowCompanies'
import Footer from '../../components/Footer'

function Home() {

  return (
    <div className='container'>
      <Navbar />
      <h1>Companies List</h1>
      <Button className='m-2' variant='info'>
        <Link style={{color:'white'}} to='addnew'>Add New Company</Link>
      </Button>
      <ShowCompanies />
      <Footer/>
    </div>
  )
}

export default Home