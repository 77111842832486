import { API_URL } from './config.js'

export default (firstName, lastName,username, email, password) => {
    return fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify({
            firstName,
            lastName,
            username,
            email,
            password,
        })
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("Login Failed")
            }
        }
        )
        .catch(err=>err.json("error while regitration API"))
}