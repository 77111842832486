import React from 'react'
import Home from './Home'
import DevHome from './DevHome'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


function Main() {

    const companyName = window.sessionStorage.getItem("companyName")
    const username = window.sessionStorage.getItem("username")
    const firstName = window.sessionStorage.getItem("firstName")
    const role = window.sessionStorage.getItem("role")

    const navigate = useNavigate()

    const handleLogout = () => {
        window.sessionStorage.clear()
        navigate('/login')
    }

    if (role == "developer") { return (<DevHome />) }
    else if (companyName && (role == "admin" || role == "user")) { return (<Home />) }
    else {
        return (
            <div className='container mt-5'>
                <Button className='d-flex align-self-end' variant='outline-dark' onClick={handleLogout}>Logout</Button>
                <h3 style={{ color: "red" }}>Acces denied.</h3>
                <h1>PLEASE ASK YOUR IT TEAM FOR ACCESS</h1>
                <h5>Dear {firstName}, </h5>
                <p>
                    Thank you for your registration, but to be able to use the
                    features of <b style={{ color: "red" }}>MaintenApp</b>  you need
                    to contact your IT department in order to add you among your
                    company members.
                </p>
                <p>
                    Otherwise, if you want to manage your own company by our application
                    please contact us on : <a href=''>twemman@hotmail.com</a>
                </p>
            </div>
        )
    }

}

export default Main