import {API_URL} from '../config'

export default async (data)=>{
    const token = window.sessionStorage.getItem('token')
    const response = await fetch(`${API_URL}/newrequestintervention`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(data)
    })
    return console.log(response.json())
    // .then(res=>console.log('done'))
    // .catch(err=>console.log("error while creating a new request intervention: ", err))
}