import {API_URL} from '../config'

export default (email)=>{
    const token = window.sessionStorage.getItem('token')
    return fetch(`${API_URL}/findOneUserByEmail/${email}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
        }
    })
    .then(res=>res.json({status:"OK"}))
    .catch(err=>console.error(err));
}

