import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import readFilteredEquipmentRequest from '../api/apiEquipment/readFilteredEquipmentRequest';
import removeEquipmentRequest from '../api/apiEquipment/removeEquipmentRequest';

function UserForm() {
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [trigger, setTrigger] = useState(0)

    const isAdmin = window.sessionStorage.getItem('role')
    const companyName = window.sessionStorage.getItem('companyName')
    const showButton = (isAdmin === "admin")

    const handleEdit = (e) => {
        navigate(`edit/${e.target.value}`)
    }

    const functionData = (e) => { setData(e) }

    useEffect(() => {
        readFilteredEquipmentRequest(companyName)
            .then((e) => functionData(e));
    }, [trigger])

    const handleDelete = (e) => {
        removeEquipmentRequest(e.target.value)
        setTrigger(trigger+1)
    }

    return (
        <div className='EquipmentList'>
            <ListGroup>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            {/* {showButton && <th >Company</th>} */}
                            <th>Référence</th>
                            <th>Désignation de l'équipement</th>
                            <th>Catégorie</th>
                            <th>Puissance (kW)</th>
                            <th>Largeur (mm)</th>
                            <th>Autre</th>
                            {showButton && <th >Edit</th>}
                            {showButton && <th>Delete</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0).sort((a,b)=>a.reference.localeCompare(b.reference)).map((e, i) => {
                            return (
                                <tr key={i} >
                                    {/* {showButton && <td >{e.companyName}</td>} */}
                                    <td >{e.reference}</td>
                                    <td >{e.equipmentName}</td>
                                    <td >{e.category}</td>
                                    <td >{e.power}</td>
                                    <td >{e.width}</td>
                                    <td >{e.other}</td>
                                    {showButton && <td>
                                        <Button
                                            variant='outline-dark'
                                            style={{ height: "35px" }}
                                            onClick={(e) => handleEdit(e)}
                                            value={e._id}
                                        >
                                            {'\ud83d\udd8a'}
                                        </Button>
                                    </td>}
                                    {showButton && <td>
                                        <Button
                                            onClick={(e) => handleDelete(e)}
                                            variant='outline-danger'
                                            value={e._id}
                                        >X</Button>
                                    </td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ListGroup>
        </div>
    );
}

export default UserForm;