import React from 'react'
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import readFilteredEquipmentRequest from '../../../api/apiEquipment/readFilteredEquipmentRequest';
import filterInterventionsRequest from '../../../api/apiIntervention/filterInterventionsRequest';


const Filters = ({ setData }) => {

  const companyName = window.sessionStorage.getItem('companyName')

  const [equipment, setEquipment] = useState([])
  const [selectEquipment, setSelectEquipment] = useState()
  // const [data, setData] = useState()

  useEffect(() => {
    readFilteredEquipmentRequest(companyName)
      .then(res => { setEquipment(res) });
  }, [])

  const handleSelect = (e) => {
    filterInterventionsRequest({ equipmentName: e })
      .then((res) => { setData(res) })
  }


  return (
    <div>
      <Form.Select aria-label="Equipment"
        onChange={(e) => handleSelect(e.target.value)}
        value={selectEquipment}
      >
        <option value={"all"}>Filter</option>
        {equipment.map((e, i) => {
          return (

            <option key={i} value={e.reference} >
              {e.reference}
            </option>

          )
        })}
      </Form.Select>

    </div>
  )
}

export default Filters