import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';

import Button from 'react-bootstrap/esm/Button';
import Navbar from '../../components/Navbar'
import './../views.css'
import DateInput from '../../components/DateInput'
import readFilteredEquipmentRequest from '../../api/apiEquipment/readFilteredEquipmentRequest'
import findOneEquipmentRequest from '../../api/apiEquipment/findOneEquipmentRequest'
import updateEquipmentRequest from '../../api/apiEquipment/updateEquipmentRequest'


const AddPage = () => {
  const companyName = window.sessionStorage.getItem("companyName")
  const [username, setUsername] = useState(window.sessionStorage.getItem("username"))
  const [trigger, setTrigger] = useState(0)
  const current = new Date()
  const currentdate = current.toLocaleDateString()

  const [equipmentList, setEquipmentList] = useState([])
  useEffect(() => {
    readFilteredEquipmentRequest(companyName)
      .then(res => {
        setEquipmentList(res)
      })
  }, [trigger])

  const [date, setDate] = useState(currentdate)
  const [poste, setPoste] = useState("")
  const [graissage, setGraissage] = useState("")
  const [id, setId] = useState("")
  const [bande, setBande] = useState("")
  const [tambour, setTambour] = useState("")
  const [motoreducteur, setMotoreducteur] = useState("")
  const [rouleau, setRouleau] = useState("")
  const [protection, setProtection] = useState("")
  const [structure, setStructure] = useState("")
  const [arretUrg, setArretUrg] = useState("")
  const [comment, setComment] = useState("")
  const navigate = useNavigate()
  const location = useLocation()

  const handleSubmit = (el) => {
    el.preventDefault()
    var checkList = []
    findOneEquipmentRequest(id)
      .then((res => {
        checkList = [...res.checkList]
        if (checkList && checkList.length > 0) {
          const bande1 = bande == "" ? checkList[0].bande : bande
          const tambour1 = tambour == "" ? checkList[0].tambour : tambour
          const motoreducteur1 = motoreducteur == "" ? checkList[0].motoreducteur : motoreducteur
          const rouleau1 = rouleau == "" ? checkList[0].rouleau : rouleau
          const protection1 = protection == "" ? checkList[0].protection : protection
          const structure1 = structure == "" ? checkList[0].structure : structure
          const arretUrg1 = arretUrg == "" ? checkList[0].arretUrg : arretUrg
          checkList.unshift({
            date, poste, username, graissage,
            bande: bande1,
            tambour: tambour1,
            motoreducteur: motoreducteur1,
            rouleau: rouleau1,
            protection: protection1,
            structure: structure1,
            arretUrg: arretUrg1,
            comment
          })
        }
        else {
          checkList = [{ date, poste, username, graissage, bande, tambour, motoreducteur, rouleau, protection, structure, arretUrg, comment }]
        }
        const data1 = { id, checkList }
        updateEquipmentRequest(data1)
        navigate('/controlePreventif')
      }))
  }

  return (
    <div className='container'>
      <Navbar />
      <h1>Checklist Controle Préventif</h1>
      {/* DIV DATE - DONEBY - POSTE */}
      <div >
        <Form className='d-flex flex-column m-2 gap-2 wrap' onSubmit={handleSubmit}>
          <div className='d-flex gap-2 justify-content-center wrap'>
            <div>
              <DateInput setDate={setDate} date={date} />

              <InputGroup>
                <InputGroup.Text>Inspecté par</InputGroup.Text>
                <Form.Control placeholder='Done By' readOnly name='username' onChange={(e) => setUsername(e.target.value)} value={username ? username : ""} />
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Poste</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='poste'
                  onChange={(e) => setPoste(e.target.value)}
                >
                  <option value="" disabled hidden>Select a shift</option>
                  <option >1</option>
                  <option >2</option>
                  <option >3</option>
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Equipement</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='reference'
                  onChange={(e) => {
                    setId(e.target.value)
                  }
                  }
                >
                  <option defaultValue="" ></option>
                  {equipmentList?.map((e, i) => {
                    return (
                      <option key={i} value={e._id}>{e.reference}</option>
                    )
                  })}
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Graissage</InputGroup.Text>
                <Form.Select
                  type='boolean'
                  name='Graissage'
                  onChange={(e) => setGraissage(e.target.value)}
                >
                  <option value="" hidden></option>
                  <option value={true}>OK</option>
                  <option value={false}>non OK</option>
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Bande</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='bande'
                  onChange={(e) => setBande(e.target.value)}
                >
                  <option value="" hidden></option>
                  <option value="ok">OK</option>
                  <option value="no">non OK</option>
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Tambour</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='tambour'
                  onChange={(e) => setTambour(e.target.value)}
                >
                  <option value="" hidden></option>
                  <option value="ok">OK</option>
                  <option value="no">non OK</option>
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Motorisation</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='motoreducteur'
                  onChange={(e) => setMotoreducteur(e.target.value)}
                >
                  <option value="" hidden></option>
                  <option value="ok">OK</option>
                  <option value="no">non OK</option>
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Rouleaux</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='rouleau'
                  onChange={(e) => setRouleau(e.target.value)}
                >
                  <option value="" hidden></option>
                  <option value="ok">OK</option>
                  <option value="no">non OK</option>
                </Form.Select>
              </InputGroup>


              <InputGroup>
                <InputGroup.Text>Protection</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='protection'
                  onChange={(e) => setProtection(e.target.value)}
                >
                  <option value="" hidden></option>
                  <option value="ok">OK</option>
                  <option value="no">non OK</option>
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Structure</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='structure'
                  onChange={(e) => setStructure(e.target.value)}
                >
                  <option value="" hidden></option>
                  <option value="ok">OK</option>
                  <option value="no">non OK</option>
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Arrêt d'urgence</InputGroup.Text>
                <Form.Select
                  type='text'
                  name='arretUrg'
                  onChange={(e) => setArretUrg(e.target.value)}
                >
                  <option value="" hidden></option>
                  <option value="ok">OK</option>
                  <option value="no">non OK</option>
                </Form.Select>
              </InputGroup>

              <InputGroup>
                <InputGroup.Text>Commentaires</InputGroup.Text>
                <input
                  onChange={(e) => setComment(e.target.value)}
                />
              </InputGroup>

            </div>
          </div>
          <div>
            <Button type='submit'> Valider</Button>
            <Button variant='secondary' onClick={e => navigate('/controlePreventif')}> Cancel</Button>
          </div>
        </Form>
      </div>
    </div >
  )
}

export default AddPage