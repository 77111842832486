import {API_URL} from '../config'

export default ()=>{
    const token = window.sessionStorage.getItem('token')
    return fetch(`${API_URL}/readcompany`,{
        method:'GET',
        headers:{
            Authorization: `Bearer ${token}`,
            "Content-Type" : 'application/json'
        }
    })
    .then(response=>response.json())
    .catch(err=>console.error(err))
}