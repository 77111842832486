import React, { useEffect, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';

import Button from 'react-bootstrap/esm/Button';
import Navbar from '../components/Navbar'
import './../views/views.css'
import readFilteredEquipmentRequest from '../api/apiEquipment/readFilteredEquipmentRequest'


const AddPage = () => {
    const companyName = window.sessionStorage.getItem("companyName")
    const [trigger, setTrigger] = useState(0)
    const current = new Date()

    const [equipmentList, setEquipmentList] = useState([])
    useEffect(() => {
        readFilteredEquipmentRequest(companyName)
            .then(res => {
                setEquipmentList(res)
            })
    }, [trigger])

    return (
        <div className='container'>
            <Navbar />
            <h1>Checklist Controle Préventif</h1>
            <Button href='/preventif'>Nouvelle inspection</Button>
            {/* DIV DATE - DONEBY - POSTE */}
            <div >
                <div className='EquipmentList'>
                    <ListGroup>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Référence</th>
                                    <th>Désignation de l'équipement</th>
                                    <th>Bande</th>
                                    <th>Tambour</th>
                                    <th>Motorisation</th>
                                    <th>Rouleaux</th>
                                    <th>Protection</th>
                                    <th>Arret d'urgence</th>
                                    <th>Dernier Controle</th>
                                    <th>Controlé par</th>
                                    <th>Dernier Commentaire</th>
                                    <th>Dernier Graissage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {equipmentList.slice(0).filter((e) => e.category === "Convoyeur").map((e, i) => {
                                    return (
                                        <tr key={i} >
                                            <td >{e.reference}</td>
                                            <td >{e.equipmentName}</td>
                                            {
                                                (e.checkList[0]?.bande === "no") ?
                                                    <td className='no'>{e.checkList[0]?.bande}</td> :
                                                    <td>{e.checkList[0]?.bande}</td>
                                            }
                                            {
                                                (e.checkList[0]?.tambour === "no") ?
                                                    <td className='no'>{e.checkList[0]?.tambour}</td> :
                                                    <td>{e.checkList[0]?.tambour}</td>
                                            }
                                            {
                                                (e.checkList[0]?.motoreducteur === "no") ?
                                                    <td className='no'>{e.checkList[0]?.motoreducteur}</td> :
                                                    <td>{e.checkList[0]?.motoreducteur}</td>
                                            }
                                            {
                                                (e.checkList[0]?.rouleau === "no") ?
                                                    <td className='no'>{e.checkList[0]?.rouleau}</td> :
                                                    <td>{e.checkList[0]?.rouleau}</td>
                                            }
                                            {
                                                (e.checkList[0]?.protection === "no") ?
                                                    <td className='no'>{e.checkList[0]?.protection}</td> :
                                                    <td>{e.checkList[0]?.protection}</td>
                                            }
                                            {
                                                (e.checkList[0]?.arretUrg === "no") ?
                                                    <td className='no'>{e.checkList[0]?.arretUrg}</td> :
                                                    <td>{e.checkList[0]?.arretUrg}</td>
                                            }
                                            <td >
                                                {e.checkList[0]?.date}<br/>
                                                {e.checkList[1]?.date}<br/>
                                                {e.checkList[2]?.date}
                                            </td>
                                            <td >
                                                {e.checkList[0]?.username}<br/>
                                                {e.checkList[1]?.username}<br/>
                                                {e.checkList[2]?.username}
                                            </td>
                                            <td >
                                                -{e.checkList[0]?.comment}
                                                <br/>
                                                -{e.checkList[1]?.comment}
                                                <br/>
                                                -{e.checkList[2]?.comment}
                                            </td>
                                            <td >
                                                {e.checkList.filter((e) => e.graissage === "true")[0]?.date}<br/>
                                                {e.checkList.filter((e) => e.graissage === "true")[1]?.date}<br/>
                                                {e.checkList.filter((e) => e.graissage === "true")[2]?.date}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </ListGroup>
                </div>
            </div>
        </div >
    )
}

export default AddPage