import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import readFilteredCPURequest from '../api/apiCPU/readCPURequest';
import removeCPURequest from '../api/apiCPU/removeCPURequest';
import LineChart from './LineChart';

function UserForm() {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [trigger, setTrigger] = useState(0)
    const [data, setData] = useState([])
    const [_id, setId] = useState("")

    const isAdmin = window.sessionStorage.getItem('role')
    const companyName = window.sessionStorage.getItem('companyName')
    const showButton = (isAdmin == "admin")

    const handleEdit = (e) => {
        navigate(`edit/${e.target.value}`)
    }

    const functionData = (e) => { setData(e) }

    useEffect(() => {
        readFilteredCPURequest()
            .then((e) => { functionData(e); setLoading(false) });
    }, [trigger])

    const handleDelete = (e) => {
        removeCPURequest(e.target.value)
            .then(res => setTrigger(trigger + 1))
    }

    return (
        <div className='CPUList'>
            {loading ?
                <div className='connecting'>Loading</div>
                :
                <>
                    <LineChart />
                    <ListGroup>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Heure</th>
                                    <th>A1(%)</th>
                                    <th>C1(A)</th>
                                    <th>C1 D(°)</th>
                                    <th>C1 G(°)</th>
                                    <th>A2(%)</th>
                                    <th>C2(A)</th>
                                    <th>C2 D(°)</th>
                                    <th>C2 G(°)</th>
                                    <th>Préstock (%)</th>
                                    {showButton && <th >Edit</th>}
                                    {showButton && <th>Delete</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {data.filter((e, i) => { return (i % 6 == 0) }).slice(-100).reverse().map((e, i) => {
                                    return (
                                        <tr key={i} >
                                            <td >{e.Date}</td>
                                            <td >{e.time}</td>
                                            <td >{e.Vitesse_A1}</td>
                                            <td >{e.Courant_M26}</td>
                                            <td >{e.Temperature_C1_D}</td>
                                            <td >{e.Temperature_C1_G}</td>
                                            <td >{e.Vitesse_A2}</td>
                                            <td >{e.Courant_M30}</td>
                                            <td >{e.Temperature_C2_D}</td>
                                            <td >{e.Temperature_C2_G}</td>
                                            <td >{e.Niveau_Prestock}</td>
                                            {showButton && <td>
                                                <Button
                                                    variant='outline-dark'
                                                    style={{ height: "35px" }}
                                                    onClick={(e) => handleEdit(e)}
                                                    value={e._id}
                                                >
                                                    {'\ud83d\udd8a'}
                                                </Button>
                                            </td>}
                                            {showButton && <td>
                                                <Button
                                                    onClick={(e) => handleDelete(e)}
                                                    variant='outline-danger'
                                                    value={e._id}
                                                >X</Button>
                                            </td>}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </ListGroup>
                </>
            }

        </div >
    );
}

export default UserForm;