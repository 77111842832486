import React from 'react'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';


const DateInput = ({setDate, date}) => {
    const current = new Date()
    const currentdate = current.toLocaleDateString("fr-FR")
    return (
        <InputGroup>
            <InputGroup.Text>Date</InputGroup.Text>
            <Form.Control
                placeholder='date'
                type='date'
                name='date'
                onChange={(e) => {
                    const localDate = e.target.value
                    console.log(localDate)
                    const localDateString = localDate.split("-")
                    const yyyy = localDateString[0]
                    const MM = localDateString[1]
                    const dd = localDateString[2]
                    setDate(`${dd}/${MM}/${yyyy}`)
                }
                }
                value={
                    date ?
                        `${date.split("/")[2]}-${date.split("/")[1]}-${date.split("/")[0]}`
                        : ""
                }

            />
        </InputGroup>
    )
}

export default DateInput