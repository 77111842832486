import './App.css';
import Login from './views/Login'
import Register from './views/Register'
import { Routes, Route, Outlet, Navigate } from "react-router-dom"

import EquipmentPage from './views/equipment/EquipmentPage'
import AddEquipmentPage from './views/equipment/AddEquipmentPage'
import EditEquipmentPage from './views/equipment/EditEquipmentPage'

import CPUPage from './views/cpu/CPUPage'
import Preventif from './views/checkListConvoyeur/AddPage'
import ShowCheckList from './components/ShowCheckList'

import InterventionsPage from './views/interventions/InterventionsPage'
import AddInterventionPage from './views/interventions/AddInterventionPage'
import EditInterventionPage from './views/interventions/EditInterventionPage'

import RequestInterventionsPage from './views/requestinterventions/RequestInterventionsPage'
import AddRequestInterventionPage from './views/requestinterventions/AddRequestInterventionPage'
import EditRequestInterventionPage from './views/requestinterventions/EditRequestInterventionPage'

import CompanyPage from './views/companies/CompanyPage'
import EditCompanyPage from './views/companies/EditCompanyPage'
import AddCompanyPage from './views/companies/AddCompanyPage'
import ProdReportPage from './views/prodReports/ProdReportPage'

import UsersPage from './views/users/UsersPage'
import EditUserPage from './views/users/EditUserPage'

import jwt_decode from 'jwt-decode'
import Main from './views/Main';
import AddProdReportPage from './views/prodReports/AddProdReportPage';

function App() {
  const ProtectedRoute = () => {
    const token = window.sessionStorage.getItem('token')
    // const decodedToken = jwt_decode(token)
    if (token) {
      return ((jwt_decode(token).exp * 5000 < Date.now()) ? <Navigate to="/login" replace /> : <Outlet />)
      // <Outlet/>
    } else {
      return (<Navigate to='/login' replace />)
    }
  }
  const ProtectedAdminRoute = () => {
    const isAdmin = (window.sessionStorage.getItem('role') === "admin")
    return (isAdmin) ? <Outlet /> : <Navigate to='/home' replace />
  }

  const ProtectedDevRoute = () => {
    const isAdmin = (window.sessionStorage.getItem('role') === "developer")
    return (isAdmin) ? <Outlet /> : <Navigate to='/home' replace />
  }


  return (
    <div className="App">
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/equipment" element={<EquipmentPage />} />

          <Route path="/production" element={<ProdReportPage />} />
          <Route path="/production/addnew" element={<AddProdReportPage />} />

          <Route path="/interventions/addnew" element={<AddInterventionPage />} />
          <Route path="/interventions/edit/:id" element={<EditInterventionPage />} />
          <Route path="/interventions" element={<InterventionsPage />} />

          <Route path="/requestinterventions/addnew" element={<AddRequestInterventionPage />} />
          <Route path="/requestinterventions/edit/:id" element={<EditRequestInterventionPage />} />
          <Route path="/requestinterventions" element={<RequestInterventionsPage />} />

          <Route index path="/" element={<Main />} />
          <Route index path="/home" element={<Main />} />

          <Route element={<ProtectedAdminRoute />}>
            <Route path="/equipment/addnew" element={<AddEquipmentPage />} />
            <Route path="/equipment/edit/:id" element={<EditEquipmentPage />} />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route path="/cpu" element={<CPUPage />} />
            <Route path="/preventif" element={<Preventif />} />
            <Route path="/controlePreventif" element={<ShowCheckList />} />
          </Route>

          <Route element={<ProtectedDevRoute />}>
            <Route path="/company" element={<CompanyPage />} />
            <Route path="/company/edit/:id" element={<EditCompanyPage />} />
            <Route path="/company/addnew" element={<AddCompanyPage />} />
            <Route path="/users/edit/:id" element={<EditUserPage />} />
            <Route path="/users" element={<UsersPage />} />
          </Route>

        </Route>

        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path="/*" element={<div>
          <h1>404 ... NOT FOUND</h1>
          <div><a href='/login'>Login</a></div>
          <div><a href='/register'>Register</a></div>
        </div>} />

      </Routes>
    </div>
  );
}

export default App;
