import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import findOneEquipmentRequest from '../../api/apiEquipment/findOneEquipmentRequest'
import updateEquipmentRequest from '../../api/apiEquipment/updateEquipmentRequest'
import Navbar from '../../components/Navbar'

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/Footer'

const EditEquipmentPage = () => {
  const { id } = useParams()
  const [reference, setReference] = useState("")
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("companyName"))
  const [equipmentName, setEquipmentName] = useState("")
  const [category, setCategory] = useState("")
  const [power, setPower] = useState("")
  const [width, setWidth] = useState("")
  const [other, setOther] = useState("")


  useEffect(() => {
    findOneEquipmentRequest(id)
      .then(res => {
        // setData(res)
        setReference(res.reference)
        setCompanyName(res.companyName)
        setEquipmentName(res.equipmentName)
        setCategory(res.category)
        setPower(res.power)
        setWidth(res.width)
        setOther(res.other)
        // console.log("reference in useEffect :", reference)
      })
  }, [])

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log("equipmentName in handlesubmit :",equipmentName)
    const data = {
      id,reference,companyName,equipmentName,category,power,width,other
    }
    updateEquipmentRequest(data)
    navigate('/equipment')
  }
  return (
    <div className='container'>
      <Navbar />
      <h1>Edit Equipment Page</h1>
      <div >
      <Form className='d-flex flex-column m-2 gap-2' onSubmit={handleSubmit}>
          <InputGroup>
            <InputGroup.Text>Reference</InputGroup.Text>
            <Form.Control
              placeholder='reference'
              name='reference'
              onChange={(e) => setReference(e.target.value)}
              value={reference}
            />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Equipment Name</InputGroup.Text>
            <Form.Control
              placeholder='equipment name' name='equipmentName' onChange={(e) => setEquipmentName(e.target.value)}
              value={equipmentName ? equipmentName : ""}
            />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Category</InputGroup.Text>
            <Form.Select
              type='text'
              name='category'
              onChange={(e) => setCategory(e.target.value)}
              value={category ? category : ""}
            >
              <option value="">choisir une catégorie</option>
              <option value="Convoyeur">Convoyeur</option>
              <option value="Crible">Crible</option>
              <option value="Local">Local</option>
              <option value="Utilite">Utilité</option>
              <option value="Concasseur">Concasseur</option>
              <option value="Moteur">Moteur</option>
              <option value="Reducteur">Réducteur</option>
              <option value="Outil">Machine Outil</option>
            </Form.Select>
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Power</InputGroup.Text>
            <Form.Control placeholder='power' name='power' onChange={(e) => setPower(e.target.value)} value={power} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Width</InputGroup.Text>
            <Form.Control placeholder='width' name='width' onChange={(e) => setWidth(e.target.value)} value={width} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Other</InputGroup.Text>
            <Form.Control placeholder='other' name='other' onChange={(e) => setOther(e.target.value)} value={other} />
          </InputGroup>
          <Button type='submit'>Submit</Button>
        </Form>
      {/* <form className='d-flex flex-column' onSubmit={handleSubmit}>
          <input placeholder='reference' name='reference' onChange={(e)=>setReference(e.target.value)}
          value={reference?reference:""}/>
          <input placeholder='Comapny Name' name='companyName' onChange={(e)=>setCompanyName(e.target.value)}
          value={companyName?companyName:""}/>
          <input placeholder='equipment name' name='equipmentName' onChange={(e)=>setEquipmentName(e.target.value)}
          value={equipmentName?equipmentName:""} />
          <input placeholder='category' name='category' onChange={(e)=>setCategory(e.target.value)} value = {category?category:""}/>
          <input placeholder='power' name='power' onChange={(e)=>setPower(e.target.value)} value={power?power:""}/>
          <input placeholder='width' name='width' onChange={(e)=>setWidth(e.target.value)} value={width?width:""}/>
          <input placeholder='other' name='other' onChange={(e)=>setOther(e.target.value)} value={other?other:""}/>
          <button type='submit'>Submit</button>
        </form> */}
      </div>
      <Footer/>
    </div>
  )
}

export default EditEquipmentPage