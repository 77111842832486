import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import addEquipmentRequest from '../../api/apiEquipment/addEquipmentRequest'
import Navbar from '../../components/Navbar'
import './../views.css'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/Footer'

const AddEquipmentPage = () => {

  const [reference, setReference] = useState("")
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("companyName"))
  const [equipmentName, setEquipmentName] = useState("")
  const [category, setCategory] = useState("")
  const [power, setPower] = useState("")
  const [width, setWidth] = useState("")
  const [other, setOther] = useState("")

  const data = {
    reference, companyName, equipmentName, category, power, width, other
  }

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    navigate('/equipment')
    addEquipmentRequest(data)
  }
  return (
    <div className='container'>
      <Navbar />
      <h1>AddEquipmentPage</h1>
      <div >
        <Form className='d-flex flex-column m-2 gap-2' onSubmit={handleSubmit}>
          <InputGroup>
            <InputGroup.Text>Reference</InputGroup.Text>
            <Form.Control
              placeholder='reference'
              name='reference'
              onChange={(e) => setReference(e.target.value)}
              value={reference}
            />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Equipment Name</InputGroup.Text>
            <Form.Control
              placeholder='equipment name' name='equipmentName' onChange={(e) => setEquipmentName(e.target.value)}
              value={equipmentName ? equipmentName : ""}
            />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Category</InputGroup.Text>
            <Form.Select
              type='text'
              name='category'
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">choisir une catégorie</option>
              <option value="Convoyeur">Convoyeur</option>
              <option value="Crible">Crible</option>
              <option value="Local">Local</option>
              <option value="Utilite">Utilité</option>
              <option value="Concasseur">Concasseur</option>
              <option value="Moteur">Moteur</option>
              <option value="Reducteur">Réducteur</option>
              <option value="Outil">Machine Outil</option>
              <option value="Alimentateur">Alimentateur</option>
            </Form.Select>
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Power</InputGroup.Text>
            <Form.Control placeholder='power' name='power' onChange={(e) => setPower(e.target.value)} value={power} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Width</InputGroup.Text>
            <Form.Control placeholder='width' name='width' onChange={(e) => setWidth(e.target.value)} value={width} />
          </InputGroup>

          <InputGroup>
            <InputGroup.Text>Other</InputGroup.Text>
            <Form.Control placeholder='other' name='other' onChange={(e) => setOther(e.target.value)} value={other} />
          </InputGroup>

          <Button type='submit'>Submit</Button>
        </Form>
      </div>
      <Footer />
    </div>
  )
}

export default AddEquipmentPage