import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import readUserRequest from '../api/apiUsers/readUserRequest';
import removeUserRequest from '../api/apiUsers/removeUserRequest';

function UserForm() {
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [_id, setId] = useState("")
    const [trigger, setTrigger] = useState(2)

    const handleEdit = (e) => {
        navigate(`edit/${e.target.value}`)
    }

    const handleDelete = (e) => {
        removeUserRequest(e.target.value)
            .then(res => setTrigger(trigger + 1))
    }

    const functionData = (e)=>{setData(e)}

    useEffect(() => {
        readUserRequest()
            .then(res => {functionData(res)});
    }, [trigger])

    return (
        <div className='interventionsList'>
            <ListGroup>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Company Name</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0).reverse().map((e, i) => {
                            return (
                                <tr key={i} >
                                    <td >{e.firstName}</td>
                                    <td >{e.lastName}</td>
                                    <td >{e.username}</td>
                                    <td >{e.email} </td>
                                    <td >{e.role}</td>
                                    <td >{e.companyName}</td>
                                    <td>
                                    <Button
                                            variant='outline-dark'
                                            style={{ height: "35px" }}
                                            onClick={(e) => handleEdit(e)}
                                            value={e._id}
                                        >
                                            {'\ud83d\udd8a'}
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            onClick={handleDelete}
                                            variant='outline-danger'
                                            value={e._id}
                                        >X</Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </ListGroup>
        </div>
    );
}

export default UserForm;