import {API_URL} from '../config'

export default async (id)=>{
    const token = window.sessionStorage.getItem('token')
    const response = await fetch(`${API_URL}/findOnecompany/${id}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json'
        }
    })
    return await response.json()
}

