import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import addRequestInterventionRequest from '../../api/apiRequestIntervention/addRequestInterventionRequest'
import Navbar from '../../components/Navbar'
import './../views.css'
import readFilteredEquipmentRequest from '../../api/apiEquipment/readFilteredEquipmentRequest'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import Footer from '../../components/Footer'
import DateInput from '../../components/DateInput'


const AddRequestInterventionPage = () => {
  const current = new Date()
  const currentdate = current.toLocaleDateString("fr-FR")
  const currenttime = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  });

  const [date, setDate] = useState(currentdate)
  const [time, setTime] = useState(currenttime)
  const [equipmentName, setEquipmentName] = useState("")
  const [requestedBy, setRequestedBy] = useState(window.sessionStorage.getItem("username"))
  const [doneBy, setDoneBy] = useState("")
  const [description, setDescription] = useState("")
  const [isDone, setisDone] = useState(false)
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("companyName"))
  const [equipmentList, setEquipmentList] = useState([])

  const data = {
    date, time, equipmentName, requestedBy, doneBy, description, isDone, companyName
  }

  useEffect(() => {
    readFilteredEquipmentRequest(companyName)
      .then(res => {
        // console.log("filtered list : ",res)
        setEquipmentList(res)
      })
  }, [])

  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    addRequestInterventionRequest(data)
    navigate('/requestinterventions')
  }
  return (
    <div className='container'>
      <Navbar />
      <h1>Demande d'intervention</h1>
      <Form className='d-flex flex-column m-2 gap-2' onSubmit={handleSubmit}>
        <DateInput setDate={setDate} date ={date} />
          {/* 
        <InputGroup>
          <InputGroup.Text>Date</InputGroup.Text>
          <Form.Control
            placeholder='date'
            type='date'
            name='date'
            onChange={(e) => {
              const localDate = e.target.value
              console.log(localDate)
              const localDateString = localDate.split("-")
              const yyyy = localDateString[0]
              const MM = localDateString[1]
              const dd = localDateString[2]
              setDate(`${dd}/${MM}/${yyyy}`)
            }
            }
            value={
              date?
                  `${date.split("/")[2]}-${date.split("/")[1]}-${date.split("/")[0]}`
              :""
            }
            
          />
        </InputGroup> */}

        <InputGroup>
          <InputGroup.Text>Heure</InputGroup.Text>
          <Form.Control
            placeholder='time'
            name='time'
            type='time'
            onChange={(e) => setTime(e.target.value)}
            value={time ? time : ""} />
        </InputGroup>

        <InputGroup>
          <InputGroup.Text>Equippement</InputGroup.Text>
          <Form.Select aria-label="Default select example" onChange={(e) => setEquipmentName(e.target.value)}>
            <option>Selectionner un équippment</option>
            {equipmentList.map((e, i) => {
              return (
                <option key={i} value={e.reference}>
                  {e.reference}
                </option>
              )
            })}
          </Form.Select>
        </InputGroup>

        <InputGroup>
          <InputGroup.Text>Demandée par</InputGroup.Text>
          <Form.Control
            placeholder='Requested By'
            readOnly
            name='requestedBy'
            // onChange={(e) => setRequestedBy(e.target.value)} 
            value={requestedBy ? requestedBy : ""} />
        </InputGroup>

        <InputGroup>
          <InputGroup.Text>Description</InputGroup.Text>
          <Form.Control
            placeholder='Description de la tache demandée'
            onChange={(e) => setDescription(e.target.value)}
            name='description'
            value={description ? description : ""} />
        </InputGroup>

        <Button type='submit'> Valider la demande</Button>
      </Form>
      <Footer />
    </div>
  )
}

export default AddRequestInterventionPage